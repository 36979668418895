import React, { useState }from 'react';

import '../styles/login.screen.style.scss';

/**Importation des images */

import Verifier from '../helpers/verifier.helper';


const VERF = new Verifier();

function RegistrationView( props ) {

    const [password_verification_results, setpassword_verification_results] = useState(
        {
            number: false,
            string: false,
            special_character: false,
            minimum_character: false,
            like_personal_information: true,
            isOk: false,
            isConfirmed: false,
        }
    );

    return (
        <div className='login'>
            <div className='login_content'>
                <div className='login_content_head'>
                    <div className='login_content_head_logo'>
                        <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 1920 1920">
                            <path d="M1581.294 1694.118c0 62.23-50.71 112.94-112.941 112.94H451.883c-62.231 0-112.942-50.71-112.942-112.94V1016.47h1242.353v677.647ZM564.824 903.529V508.235c0-217.976 177.317-395.294 395.294-395.294 217.976 0 395.294 177.318 395.294 395.294h112.94C1468.353 228.028 1240.326 0 960.119 0S451.882 228.028 451.882 508.235V903.53H226v790.589C226 1818.692 327.308 1920 451.882 1920h1016.47c124.575 0 225.883-101.308 225.883-225.882V903.529H564.824Zm338.823 677.647h112.941v-338.823h-112.94v338.823Z" fill-rule="evenodd"/>
                        </svg>
                    </div>
                    <div className='login_content_head_title'>
                        <div className='login_content_head_title_top'>
                            Créer un compte
                        </div>
                        <div className='login_content_head_title_bottom'>
                            <span>Veuillez saisir vos informations pour rejoindre</span>
                        </div>
                    </div>
                </div>

                <form action='#' method='POST' className='login_content_form' name='registrationForm'
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <div className='login_content_form_input'>
                        <div className='login_content_form_input_title'>
                            <span>Nom</span>
                        </div>
                        <div className='login_content_form_input_balise'>
                            <input name='lastName' required placeholder='nom' type='text'/>
                        </div>
                        <div className='login_content_form_input_subText'>
                            <span>Votre nom pour l'application</span>
                        </div>
                    </div>

                    <div className='login_content_form_input'>
                        <div className='login_content_form_input_title'>
                            <span>Prénom</span>
                        </div>
                        <div className='login_content_form_input_balise'>
                            <input name='firstName' required placeholder='Prénom' type='text'/>
                        </div>
                        <div className='login_content_form_input_subText'>
                            <span>Votre prénom pour l'application</span>
                        </div>
                    </div>

                    <div className='login_content_form_input'>
                        <div className='login_content_form_input_title'>
                            <span>Nom d'utilisateur</span>
                        </div>
                        <div className='login_content_form_input_balise'>
                            <input name='username' required placeholder="monnomdutilisateur" type='text'/>
                        </div>
                        <div className='login_content_form_input_subText'>
                            <span>Votre nom d'utilisateur pour l'application</span>
                        </div>
                    </div>

                    <div className='login_content_form_input'>
                        <div className='login_content_form_input_title'>
                            <span>Numéro de téléphone</span>
                        </div>
                        <div className='login_content_form_input_balise'>
                            <input name='phoneNumber' required placeholder='xxxxxxxx' type='tel'/>
                        </div>
                        <div className='login_content_form_input_subText'>
                            <span>Votre numéro de téléphone</span>
                        </div>
                    </div>

                    <div className='login_content_form_input'>
                        <div className='login_content_form_input_title'>
                            <span>Mot de passe</span>
                        </div>
                        <div className='login_content_form_input_balise'>
                            <input name='password' required placeholder='***********' type='password'
                                onChange={(e) => {
                                    let value = e.target.value;
                                    let password_verification_results_ = {
                                        number: false,
                                        string: false,
                                        special_character: false,
                                        minimum_character: false,
                                        like_personal_information: true,
                                        isOk: false,
                                        isConfirmed: false,
                                    };
                                    let result = VERF._password(value, 8);

                                    password_verification_results_.minimum_character = result.minimum_character;
                                    password_verification_results_.special_character = result.special_character;
                                    password_verification_results_.string = result.string;
                                    password_verification_results_.number = result.number;

                                    
                                    if( 
                                        ( document.forms["registrationForm"]["lastName"].value.length > 0 && value.toLowerCase().split(document.forms["registrationForm"]["lastName"].value.toLowerCase()).length > 1 )||
                                        ( document.forms["registrationForm"]["firstName"].value.length > 0 && value.toLowerCase().split(document.forms["registrationForm"]["firstName"].value.toLowerCase()).length > 1 )||
                                        ( document.forms["registrationForm"]["phoneNumber"].value.length > 0 && value.toLowerCase().split(document.forms["registrationForm"]["phoneNumber"].value.toLowerCase()).length > 1 )
                                        // ( document.forms["registrationForm"]["mail"].value.length > 0 && value.toLowerCase().split(document.forms["registrationForm"]["mail"].value.toLowerCase()).length > 1 )
                                    ) {
                                        password_verification_results_.like_personal_information = true;
                                    }else {
                                        password_verification_results_.like_personal_information = false;
                                    }

                                    if( document.forms["registrationForm"]["confirmPassword"].value.length > 0 &&  document.forms["registrationForm"]["confirmPassword"].value === value ) {
                                        password_verification_results_.isConfirmed = true;
                                    }else {
                                        password_verification_results_.isConfirmed = false;
                                    }

                                    setpassword_verification_results(password_verification_results_);
                                }}
                            />
                        </div>
                        <div className='login_content_form_input_verf'>
                            <span
                                style={{
                                    color: password_verification_results.like_personal_information ? "#d11919" : "#5dc269",
                                }}
                            >- Votre mot de passe ne peut pas trop ressembler à vos autres informations personnelles.</span>
                            
                            <span
                                style={{
                                    color: !password_verification_results.minimum_character ? "#d11919" : "#5dc269",
                                }}
                            >- Votre mot de passe doit contenir au minimum 8 caractères.</span>
                            
                            <span
                                style={{
                                    color: !password_verification_results.number ? "#d11919" : "#5dc269",
                                }}
                            >- Votre mot de passe doit contenir au moins un chiffre.</span>
                            
                            <span
                                 style={{
                                    color: !password_verification_results.string ? "#d11919" : "#5dc269",
                                }}
                            >- Votre mot de passe doit contenir au moins une lettre.</span>
                            
                            <span
                                style={{
                                    color: !password_verification_results.special_character ? "#d11919" : "#5dc269",
                                }}
                            >- Votre mot de passe doit contenir au moins un des caractères suivant : « @ », « # », « % », « & », « $ » et « _ ».</span>
                        </div>
                    </div>

                    <div className='login_content_form_input'>
                        <div className='login_content_form_input_title'>
                            <span>Confirmez votre mot de passe</span>
                        </div>
                        <div className='login_content_form_input_balise'>
                            <input name='confirmPassword' required placeholder='***********' type='password'
                                onChange={(e) => {
                                    let value = e.target.value;

                                    if( document.forms["registrationForm"]["password"].value.length > 0 &&  document.forms["registrationForm"]["password"].value === value ) {
                                        setpassword_verification_results({
                                            ...password_verification_results,
                                            isConfirmed: true,
                                        })
                                    }else {
                                        setpassword_verification_results({
                                            ...password_verification_results,
                                            isConfirmed: false,
                                        })
                                    }
                                }}
                            />
                        </div>
                        <div className='login_content_form_input_verf'>
                            <span
                                style={{
                                    color: !password_verification_results.isConfirmed ? "#d11919" : "#5dc269",
                                }}
                            >confirmer</span>
                        </div>
                    </div>

                    <div className='login_content_form_button'>
                        <button>
                            Envoyer
                        </button>
                        <a href='/login'>
                            Se connecter
                        </a>
                    </div>

                    <div className='login_content_form_button returnHome'>
                        <a href='/'>
                            Retourner à l'accueil
                        </a>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default RegistrationView;