import React, {useState, useContext, useEffect} from 'react';

// import { ContextP } from "../context/provider";

import '../styles/newsView.screen.style.scss';

import PATH_ from '../GLOBAL-VAR/globals-urls';

import Card from '../components/card/card.component';

import { ContextP } from "../context/provider";

function NewsView( props ) {
    const {getARTICLE} = useContext(ContextP);

    const [ARTICLE, setARTICLE] = useState(null);

    const path_ = new PATH_();

    function _get_article(page) {
        getARTICLE(20,page,(res)=>{
            // console.log(res);
            setARTICLE(res.data);
        }, (e)=>{
            return false;
        });
    }

    useEffect(() => {
        _get_article(1);
    }, [])

    return (
        <div className='homeViewContent'>
            <div className=''>
                <div className="news-page">
                    <header className="news-header">
                        <h1>Actualités</h1>
                        <br/>
                        <p>
                            Les dernières nouvelles de l'ORGANISATION POUR LA RESTAURATION<br/> DE L'ESPOIR DES DÉMUNIS DE LA SOCIÉTÉ
                        </p>
                        {/* <p>Les dernières nouvelles du monde de la mode durable</p> */}
                    </header>

                    {
                        ARTICLE !== null ?
                            ARTICLE.actualites.length > 0 &&
                            <section className="latest-news">
                                <img src={path_.api_root+"fonctions/media/"+ARTICLE.actualites[0].image_actualite + "/"} alt={"OREDS"} className="latest-news-image" />
                                <div className="latest-news-content">
                                    <h2>{ARTICLE.actualites[0].titre}</h2>
                                    <p dangerouslySetInnerHTML={{__html: ARTICLE.actualites[0].courte_description}}></p>
                                    <a href={"/news/"+ARTICLE.actualites[0].id} className="read-more">Lire plus</a>
                                </div>
                            </section>
                        :
                            null
                    }
                    
                </div>
            </div>
            <div className='homeViewContent_section4'>
                <div className='homeViewContent_section4_content'>
                    <div className="homeViewContent_section4_content_list">
                        {
                            ARTICLE!==null &&
                            ARTICLE.actualites.map((article, index)=>(
                                index < 3 &&
                                <div className='homeViewContent_section4_content_list_elt' key={"news" + index}>
                                    <Card elt={article} link={"/news/"+article.id} type="article"/>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsView;