import React, {useState, useContext, useEffect} from 'react';

// import { ContextP } from "../context/provider";

import '../styles/oneNewsView.screen.style.scss';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';

import {
  useParams
} from "react-router-dom";

import PATH_ from '../GLOBAL-VAR/globals-urls';

import { ContextP } from "../context/provider";

import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

dayjs.locale('fr');

function OneProjectView( props ) {
    const { current } = useParams();
    
    const {getOnePROJECT} = useContext(ContextP);
    const {getPROJECT_ODD} = useContext(ContextP);
    const {getPROJECT_ALBUM} = useContext(ContextP);
    const {getPROJECT_Expertise} = useContext(ContextP);

    const [ARTICLE, setARTICLE] = useState(null);

    const [EXPERTISEtags, setEXPERTISEtags] = useState([]);
    const [ODDtags, setODDtags] = useState([]);
    const [currentImages, setCurrentImages] = useState([]);

    const path_ = new PATH_();

    function _get_project_odd(id, callback=()=>{}) {
      getPROJECT_ODD(id,(res)=>{
          setODDtags(res.data.odd);
          callback();
      }, (e)=>{
          return false;
      });
  }

  function _get_project_expertise(id, callback=()=>{}) {
      getPROJECT_Expertise(id,(res)=>{
          setEXPERTISEtags(res.data.domaines);
          callback();
      }, (e)=>{
          return false;
      });
  }

  function _get_project_album(id, callback=()=>{}) {
      getPROJECT_ALBUM(id,(res)=>{
          setCurrentImages(res.data.medias);
          callback();
      }, (e)=>{
          return false;
      });
  }

    function _get_PROJECT(id) {
      getOnePROJECT(id,(res)=>{
        // console.log(res.data);
          setARTICLE(res.data);
      }, (e)=>{
          return false;
      });
    }

    const article = {
        title: "Titre de l'article",
        image: "url_de_l_image_de_l_article", // Remplace par l'URL réelle de l'image
        date: "9 août 2024",
        author: "Nom de l'auteur",
        content: `
          Ceci est le contenu principal de l'article. Il peut contenir plusieurs paragraphes
          pour expliquer les détails de l'actualité. On peut ajouter des sous-titres, des images,
          ou des citations pour enrichir le texte.
        `,
      };
    
      const relatedArticles = [
        {
          title: "Article connexe 1",
          link: "#",
        },
        {
          title: "Article connexe 2",
          link: "#",
        },
        {
          title: "Article connexe 3",
          link: "#",
        },
      ];

    useEffect(() => {
      // console.log(current);
      if(current !== null) {
        _get_PROJECT(current);
        _get_project_odd(current, ()=>{
          _get_project_expertise(current, ()=>{
              _get_project_album(current, ()=>{});
          });
        });
      }
    }, [])

    return (
        <div className='homeViewContent'>
            <div className=''>
              {
                ARTICLE !== null &&
                  <div className="article-page">
                    <header className="article-header">
                      <h1>{ARTICLE.titre}</h1>
                    </header>

                    <div>
                      <img src={path_.api_root+"fonctions/media/"+ARTICLE.image_projet + "/"} alt={article.title} className="article-image" />
                      <div className="article-meta">
                        <strong>{dayjs(ARTICLE.date_debut).format('dddd, DD MMMM, YYYY')}</strong> au <strong>{dayjs(ARTICLE.date_fin).format('dddd, DD MMMM, YYYY')}</strong>
                      </div>

                      <div className="OnProjectView_top_subtext">
                            <span className="OnProjectView_top_subtext_ title">
                                #Domaines_de_compétences
                            </span>
                        </div>
                        <div className="OnProjectView_top_competence">
                            {
                                EXPERTISEtags.length>0 &&
                                EXPERTISEtags.map((competence, index) => (
                                    <a href={"/competence/" + competence.id + "/"} className="OnCompetence_" key={index}>
                                        <span className="OnProjectView_top_competence_text">
                                            { competence.nom }
                                        </span>
                                    </a>
                                ))
                            }
                        </div>
                      </div>

                      <div>
                        <div className="OnProjectView_odd">
                            {
                                ODDtags.map((odd, index) => (
                                    <div key={"ODD" + index} className="OnProjectView_odd_ x-animation"
                                        x-animation="from-bottom"
                                        x-animation-delay="0s"
                                        x-animation-duration="2s"
                                    >
                                        <a href={"/ODD/" + odd.id + "/"}>
                                            <img className="OnProjectView_odd_img" alt="odd" src={"/ODD/odd" + odd.numero + ".png"}/>
                                        </a>
                                    </div>
                                ))
                            }
                        </div>
                      </div>

                    <main className="article-content">
                      <br/>
                      <p dangerouslySetInnerHTML={{__html: ARTICLE.courte_description}}></p>
                      <br/>
                      <p dangerouslySetInnerHTML={{__html: ARTICLE.description}}></p>
                    </main>

                    <br/>
                    <br/>
                    {
                        currentImages.length>0 &&
                        <div className="album_image">
                          <Box sx={{ width: "100%", height: 450, overflowY: 'scroll' }}>
                            <ImageList variant="masonry" cols={3} gap={8}>
                              {currentImages.map((item) => (
                                <ImageListItem key={item.image_url}>
                                  <img
                                    srcSet={path_.api_root+ "fonctions/" + item.image_url + "/"}
                                    src={path_.api_root+ "fonctions/" + item.image_url + "/"}
                                    alt={""}
                                    loading="lazy"
                                  />
                                </ImageListItem>
                              ))}
                            </ImageList>
                          </Box>
                        </div>
                    }

                    <aside className="sidebar">
                      
                    </aside>
                  </div>
              }
            </div>
        </div>
    );
}

export default OneProjectView;