import React, {useState, useContext, useEffect} from 'react';

// import { ContextP } from "../context/provider";

import '../styles/homeView.screen.style.scss';

import ImageSlider1 from '../components/imageSlider1/imageSlider1.component';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**Importation des images */
import back2 from '../assets/images/oracion-lunes-comenzar-semana.jpg';
import img1 from '../assets/images/jpg_photo_10.jpg';

import obj1 from '../assets/images/3895251.jpg';
import obj2 from '../assets/images/5549800.jpg';
import obj3 from '../assets/images/19198762.jpg';
import obj4 from '../assets/images/6849070.jpg';
import img2 from '../assets/images/experts/pres.jpg';

import Card from '../components/card/card.component';

import { ContextP } from "../context/provider";

function HomeView( props ) {
    const {getPROJECT} = useContext(ContextP);
    const {getARTICLE} = useContext(ContextP);

    const [PROJECT, setPROJECT] = useState(null);
    const [ARTICLE, setARTICLE] = useState(null);
    
    const portefolioImageList = [
        {
            src: obj1,
            text: ["Créer des centres de formation pédagogique au profit des enfants, jeunes et adultes dans le cadre de l’autothérapie et du bien-être par le biais des associations caritatives."],
            src_: obj1,
            text_: "Description de l'image"
        },
        {
            src: obj2,
            text: ["Réaliser des projets à caractère social et humanitaire afin de contribuer au développement de la société de l'État du Bénin."],
            src_: obj2,
            text_: "Description de l'image"
        },
        {
            src: obj3,
            text: ["Mettre en place des projets communautaires tels que le forage de puits, l’assistance aux personnes démunies et déscolarisées."],
            src_: obj3,
            text_: "Description de l'image"
        },
        {
            src: obj4,
            text: ["Mettre en place d'orphelinats en milieu rural et local au Bénin."],
            src_: obj3,
            text_: "Description de l'image"
        },
    ];

    const objectList = [
        {
            img: obj1,
            title: "Créer des centres de formation pédagogique au profit des enfants, jeunes et adultes dans le cadre de l’autothérapie et du bien-être par le biais des associations caritatives.",
            content: `
                <p>Cette initiative vise à offrir des opportunités d'éducation et de développement personnel à travers des centres de formation dédiés. Ces centres auront pour vocation de transmettre des compétences pratiques et théoriques, tout en promouvant le bien-être mental et physique des participants.</p>
    
                <h3>Plans d'actions :</h3>
                <ul>
                    <li>Établir des partenariats avec des experts en pédagogie, psychologie et développement personnel pour élaborer les programmes de formation.</li>
                    <li>Construire ou rénover des infrastructures adéquates pour accueillir les centres de formation.</li>
                    <li>Développer des modules adaptés aux besoins spécifiques des enfants, des jeunes et des adultes.</li>
                    <li>Suivre l'évolution des participants pour mesurer les bienfaits de ces formations sur leur vie quotidienne.</li>
                </ul>
                
                <h3>Espérances :</h3>
                <p>Nous aspirons à ce que ces centres deviennent des lieux de transformation, où chaque individu puisse développer ses compétences, renforcer son estime de soi, et contribuer activement à la société.</p>
    
            `
        },
        {
            img: obj2,
            title: "Réaliser des projets à caractère social et humanitaire afin de contribuer au développement de la société de l'État du Bénin.",
            content: `
                <p>L'ONG se fixe pour mission de répondre aux besoins fondamentaux des populations vulnérables à travers des projets sociaux et humanitaires. Cela inclut des initiatives dans les domaines de la santé, de l'éducation, de l'accès à l'eau potable et à la nourriture, ainsi que l'amélioration des conditions de vie.</p>
                <h3>Plans d'actions :</h3>
                <ul>
                    <li>Identifier les zones les plus vulnérables nécessitant une intervention.</li>
                    <li>Collaborer avec des partenaires locaux et internationaux pour lever des fonds et obtenir du soutien.</li>
                    <li>Déployer des équipes sur le terrain pour l'exécution des projets, en s'assurant de leur durabilité.</li>
                    <li>Évaluer régulièrement les impacts des projets pour ajuster les actions en fonction des besoins évolutifs.</li>
                </ul>
                <h3>Espérances :</h3>
                <p>Nous espérons voir une amélioration tangible des conditions de vie des bénéficiaires, avec un accès accru aux services essentiels et une participation active des communautés locales dans le processus de développement.</p>
            `
        },
        {
            img: obj3,
            title: "Mettre en place des projets communautaires tels que le forage de puits, l’assistance aux personnes démunies et déscolarisées",
            content: `
                <p>L'accès à l'eau potable, l'éducation et l'assistance aux plus démunis sont au cœur de cet objectif. L'ONG vise à soulager les souffrances des populations les plus marginalisées à travers des projets concrets et durables.</p>
    
                <h3>Plans d'actions :</h3>
                <ul>
                    <li>Effectuer des études de terrain pour localiser les besoins en eau potable et en assistance éducative.</li>
                    <li>Mobiliser des ressources pour le forage de puits dans les zones rurales et périurbaines.</li>
                    <li>Mettre en place des programmes de soutien scolaire et des centres d'accueil pour les enfants déscolarisés.</li>
                    <li>Créer des unités mobiles pour apporter une aide immédiate aux personnes en situation de grande précarité.</li>
                </ul>
                
                <h3>Espérances :</h3>
                <p>Nous visons à réduire le taux de déscolarisation, à améliorer l'accès à l'eau potable et à fournir un soutien vital aux plus vulnérables, contribuant ainsi à une société plus équitable et juste.</p>
    
            `
        },
        {
            img: obj4,
            title: "Mettre en place des orphelinats en milieux rural, urbain et périurbain au Bénin.",
            content: `
                <p>En réponse à la vulnérabilité des enfants orphelins, l'ONG s'engage à créer des orphelinats dans différents milieux au Bénin. Ces structures offriront un foyer sécurisé, un soutien affectif et une éducation adaptée pour permettre à ces enfants de se reconstruire.</p>
    
                <h3>Plans d'actions :</h3>
                <ul>
                    <li>Rechercher et sécuriser des terrains dans des zones stratégiques pour la construction des orphelinats.</li>
                    <li>Collaborer avec les autorités locales et des experts en protection de l'enfance pour garantir un environnement sûr et nourrissant.</li>
                    <li>Assurer une prise en charge holistique des enfants, incluant leur santé physique, mentale et leur éducation.</li>
                    <li>Organiser des campagnes de sensibilisation pour mobiliser du soutien autour de la cause des orphelins.</li>
                </ul>
                
                <h3>Espérances :</h3>
                <p>Nous espérons offrir à ces enfants une nouvelle chance dans la vie, en leur fournissant les soins et l'éducation nécessaires pour devenir des citoyens épanouis et responsables, capables de contribuer positivement à la société.</p>

            `
        }
    ]

    function _get_(page) {
        getPROJECT(20,page,(res)=>{
            console.log(res);
            setPROJECT(res.data);
        }, (e)=>{
            return false;
        });
    }

    function _get_article(page) {
        getARTICLE(20,page,(res)=>{
            // console.log(res);
            setARTICLE(res.data);
        }, (e)=>{
            return false;
        });
    }

    useEffect(() => {
        _get_(1);
        _get_article(1);
    }, [setPROJECT, setARTICLE])

    return (
        <div className='homeViewContent'>
            <section className="homeViewContent_section11">
                <div className='AboutUsContent_section_back'></div>
                <div className="homeViewContent_section11_prtfl">
                    <ImageSlider1
                        delay="5000"
                        onClickText={(text) => {}}
                        imageList={portefolioImageList}
                        anotherContent={() => {
                            return (
                                <div>
                                    {/* <table className='grid'>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                                </div>
                            )
                        }}
                    />
                </div>
            </section>
            <div className='homeViewContent_section1'>
                <div className='homeViewContent_section1_content'>
                    <div className="homeViewContent_section1_content_enc">
                        <span>OREDS</span>
                    </div>
                    <div className='homeViewContent_section1_content_new'>
                        <div>
                            <h2>
                                ORGANISATION <br/>POUR LA RESTAURATION<br/> DE L'ESPOIR DES DÉMUNIS DE LA SOCIÉTÉ
                            </h2>
                        </div>
                        <div className='homeViewContent_section1_content_new_info'>
                            <div>
                                <p>
                                    L'Organisation pour la Restauration de l'Espoir des Démunis de la Société (OREDS) est une organisation non gouvernementale dédiée à la réhabilitation et à l'amélioration des conditions de vie des populations vulnérables et marginalisées. Nous croyons fermement que chaque individu mérite une chance équitable de vivre avec dignité, indépendamment de ses origines ou de sa situation économique.
                                </p>
                                <a className='button-style-1' href="##">Contribuer à l'action d'OREDS</a>
                            </div>
                            <div>
                                <img src={back2} alt='erepere'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section id="presentation-directeur">
                <h2>Message du Directeur</h2>
                <p>
                    Chers membres, partenaires et sympathisants de l'Organisation pour la Restauration de l'Espoir des Démunis de la Société (ORESDS), je tiens à vous remercier pour votre engagement constant envers notre mission. 
                </p>
                <p>
                    Depuis sa création, notre ONG s'est efforcée de redonner espoir à ceux qui en ont le plus besoin, en leur offrant des opportunités de développement personnel et collectif. Nos initiatives en matière d'éducation, de formation, et d'assistance sociale sont le reflet de notre engagement envers un Bénin plus solidaire et plus juste.
                </p>
                <p>
                    En tant que directeur, je suis fier de tout ce que nous avons accompli ensemble, et je suis convaincu que, grâce à votre soutien continu, nous pourrons atteindre de nouveaux sommets dans notre mission. Que cet engagement reste fort et que notre impact grandisse pour toucher encore plus de vies.
                </p>
                <p>
                    Merci pour votre confiance et votre soutien indéfectible.
                </p>
                <p>
                    Cordialement,
                </p>
                <p className='picture'>
                    <img src={img2} alt='OREDS'/>
                    <p><strong>Abdoul Bassiti ISSA</strong><br/></p>
                    Directeur, Organisation pour la Restauration de l'Espoir des Démunis de la Société
                </p>
            </section>


            <div className='homeViewContent_section2'>
                
                <div className="homeViewContent_section2_">
                    <div className="homeViewContent_section1_content_enc">
                        <span>Nos objectifs</span>
                    </div>
                    {
                        objectList.map((obj, index) => (
                            <div className='homeViewContent_section2_content'
                                style={{
                                    flexDirection: index%2===0 ? "row": "row-reverse"
                                }}
                            >
                                <img src={obj.img} alt=''/>
                                <Accordion style={{
                                    boxShadow: "0px 0px 0px 0px #000000",
                                    borderRadius: "0px",
                                }} expanded={true}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={obj.title}
                                        id={obj.title}
                                    >
                                        <h2 className='faq_title'>
                                            {obj.title}
                                        </h2>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="goalBody" dangerouslySetInnerHTML={{__html: "<p className='textForm'>"+obj.content+"</p>"}}></div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            
                        ))
                    }
                    <div className='homeViewContent_section2_link'>
                        <a href='##' className='button-style-1'>
                            EN SAVOIR PLUS SUR NOUS...
                        </a>
                    </div>
                </div>
            </div>


            <div className='homeViewContent_section4'>
                <div className='homeViewContent_section4_content'>
                    <div className="homeViewContent_section4_content_enc">
                        <span>Actualités</span>
                    </div>

                    <div className="homeViewContent_section4_content_list">
                        {
                            ARTICLE!==null &&
                            ARTICLE.actualites.map((article, index)=>(
                                index < 3 &&
                                <div className='homeViewContent_section4_content_list_elt' key={"news" + index}>
                                    <Card elt={article} link={"/news/"+article.id} type="article"/>
                                </div>
                            ))
                        }
                    </div>

                    <div className="homeViewContent_section4_content_more">
                        <a href='./news'>
                            <span>afficher plus </span>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-double-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
                                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>


            <div className='homeViewContent_section4' style={{background: "#ffffff"}}>
                <div className='homeViewContent_section4_content' style={{background: "#ffffff"}}>
                    <div className="homeViewContent_section4_content_enc">
                        <span>Projets</span>
                    </div>

                    <div className="homeViewContent_section4_content_list">
                        {
                            PROJECT!==null &&
                            PROJECT.projets.map((project, index)=>(
                                index<3 &&
                                <div className='homeViewContent_section4_content_list_elt' key={"projects" + index}>
                                    <Card elt={project} link={"/project/"+project.id} />
                                </div>
                            ))
                        }
                    </div>

                    <div className="homeViewContent_section4_content_more">
                        <a href='./projects/en cours'>
                            <span>afficher plus </span>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-double-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
                                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeView;