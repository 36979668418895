import React, {useState, useContext, useEffect} from 'react';
import {
    useParams
} from "react-router-dom";
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

import ImageSelector from '../components/imageSelector/imageSelector.component';

import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";

import '../styles/dashboard.faq.style.scss';

import PATH_ from '../GLOBAL-VAR/globals-urls';

import { ContextP } from "../context/provider";
function DashboardAdminProject( props ) {

    const { page } = useParams();

    const {getODD} = useContext(ContextP);
    const {getCOMPETENCES} = useContext(ContextP);

    const {getPROJECT} = useContext(ContextP);
    const {addPROJECT} = useContext(ContextP);

    const {addODDToPROJECT} = useContext(ContextP);
    const {updatePROJECT_ODD} = useContext(ContextP);
    const {getPROJECT_ODD} = useContext(ContextP);
    const {updatePROJECT_Expertise} = useContext(ContextP);

    const {addAlbumToPROJECT} = useContext(ContextP);
    const {getPROJECT_ALBUM} = useContext(ContextP);
    const {deletePROJECT_ALBUM} = useContext(ContextP);
    
    
    const {addExpertiseToPROJECT} = useContext(ContextP);
    const {getPROJECT_Expertise} = useContext(ContextP);

    const {updatePROJECT} = useContext(ContextP);
    const {deletePROJECT} = useContext(ContextP);

    const path_ = new PATH_();

    // const {setALertMessage} = useContext(ContextP);
    // const {setCurrentAlertMessage} = useContext(ContextP);
    // const {setCurrentAlertType} = useContext(ContextP);
    // const {setCurrentAlertAction} = useContext(ContextP);

    const [ImageSelectedPreviewLink, setImageSelectedPreviewLink] = useState([]);
    const [filesSelected, setFilesSelected] = useState([]);

    const [domaineList, setDomaineList] = useState([]);
    const [ODDList, setODDList] = useState([]);
    const [EXPERTISEtags, setEXPERTISEtags] = useState([]);
    const [ODDtags, setODDtags] = useState([]);
    const [currentImages, setCurrentImages] = useState([]);


    const [PROJECT, setPROJECT] = useState(null);
    // const [CATEGORY, setCATEGORY] = useState(null);
    const [actionType, setActionType] = useState("add");
    const [currentPROJECT, setCurrentPROJECT] = useState(null);
    

    function reset() {
        setCurrentPROJECT(null);
        setODDtags([]);
        setEXPERTISEtags([]);
        setImageSelectedPreviewLink([]);
        setFilesSelected([]);
    }

    function _add_() {
        let data = new FormData();
        data.append("titre", currentPROJECT.titre);
        data.append("nom", currentPROJECT.titre);
        data.append("courte_description", currentPROJECT.courte_description);
        data.append("description", currentPROJECT.description);
        data.append("statut", currentPROJECT.statut);
        data.append("date_debut", currentPROJECT.date_debut);
        data.append("date_fin", currentPROJECT.date_fin);

        // console.log(data);

        if(document.forms["projectForm"]["file"].files.length>0) {
            data.append("image_projet", document.forms["projectForm"]["file"].files[0]);
        }
        addPROJECT(data, (res)=>{
            let associateData = {
                "odd_ids": ODDtags
            };
            // console.log(res);
            addODDToPROJECT(res.data.id_projet  ,associateData, (res_)=>{
                let associateData_ = {
                    "domaine_ids": EXPERTISEtags
                };

                addExpertiseToPROJECT(res.data.id_projet  ,associateData_, (res__)=>{
                    if(filesSelected.length>0) {
                        let data_album = new FormData();
                        for (let i = 0; i < filesSelected.length; i++) {
                            data_album.append("images", filesSelected[i]);
                            
                        }
                        data_album.append("descriptions", "");
                        addAlbumToPROJECT(res.data.id_projet  ,data_album, (res_i_)=>{
                            reset();
                            if(page) {
                                _get_(page);
                            } else {
                                _get_(1);
                            }
                        }, (e)=>{
                            console.log(e);
                        });
                    } else {
                        reset();
                        if(page) {
                            _get_(page);
                        } else {
                            _get_(1);
                        }
                    }
                    
                }, (e)=>{
                    console.log(e);
                });
            }, (e)=>{
                console.log(e);
            });
        }, (e)=>{
            console.log(e);
        });
    }

    function _update_() {
        if(window.confirm("Voulez-vous vraiment modifier ce projet ?")) {
            let data = new FormData();
            data.append("titre", currentPROJECT.titre);
            data.append("nom", currentPROJECT.titre);
            data.append("courte_description", currentPROJECT.courte_description);
            data.append("description", currentPROJECT.description);
            data.append("statut", currentPROJECT.statut);
            if(document.forms["projectForm"]["file"].files.length>0) {
                data.append("image_projet", document.forms["projectForm"]["file"].files[0]);            
            }
            updatePROJECT(currentPROJECT.id, data, (res)=>{
                let associateData = {
                    "odd_ids": ODDtags
                };
                updatePROJECT_ODD(currentPROJECT.id  ,associateData, (res_)=>{
                    let associateData_ = {
                        "domaine_ids": EXPERTISEtags
                    };
                    updatePROJECT_Expertise(currentPROJECT.id  ,associateData_, (res__)=>{
                        if(filesSelected.length>0) {
                            let data_album = new FormData();
                            for (let i = 0; i < filesSelected.length; i++) {
                                data_album.append("images", filesSelected[i]);
                                
                            }
                            data_album.append("descriptions", "");
                            addAlbumToPROJECT(currentPROJECT.id  ,data_album, (res__)=>{
                                reset();
                                if(page) {
                                    _get_(page);
                                } else {
                                    _get_(1);
                                }
                            }, (e)=>{
                                console.log(e);
                            });
                        }else {
                            reset();
                            if(page) {
                                _get_(page);
                            } else {
                                _get_(1);
                            }
                        }
                    }, (e)=>{
                        console.log(e);
                    });
                }, (e)=>{
                    console.log(e);
                });
            }, (e)=>{
                console.log(e);
            });
        }
    }

    function _delete_(id) {
        // console.log(id);
        if(window.confirm("Voulez-vous vraiment supprimer ce projet ?")) {
            deletePROJECT(id, (res)=>{
                setCurrentPROJECT(null);
                // setALertMessage(false);
                if(page) {
                    _get_(page);
                } else {
                    _get_(1);
                }
            }, (e)=>{
                console.log(e);
            })
        }
    }

    function _delete_album(id, data) {
        // console.log(id);
        if(window.confirm("Voulez-vous vraiment supprimer ces fichiers ?")) {
            deletePROJECT_ALBUM(id, data, (res)=>{
                _get_project_album(currentPROJECT.id);
            }, (e)=>{
                console.log(e);
            })
        }
    }

    function _get_(page) {
        getPROJECT(20,page,(res)=>{
            console.log(res);
            setPROJECT(res.data);
        }, (e)=>{
            return false;
        });
    }

    function _deleteImage(imageIndex) {
        let ImageSelectedPreviewLink_ = ImageSelectedPreviewLink;

        let filteredImageSelectedPreviewLink = ImageSelectedPreviewLink_.filter(function (value, index, arr) {
            return index !== imageIndex;
        });
        setImageSelectedPreviewLink(filteredImageSelectedPreviewLink);
    }

    function _deleteAllImages() {
        setImageSelectedPreviewLink([]);
    }

    function isTarget( bool, target, type ) {
        // console.log(target);
        if( type === "odd" ){
            if (bool) {
                // Ajouter l'expertise.id si la checkbox est cochée
                setODDtags([...ODDtags, target.id]);
            } else {
                // Retirer l'expertise.id si la checkbox est décochée
                setODDtags(ODDtags.filter(id => id !== target.id));
            }
        }
        else if( type === "expertise" ) {
            if (bool) {
                // Ajouter l'expertise.id si la checkbox est cochée
                setEXPERTISEtags([...EXPERTISEtags, target.id]);
            } else {
                // Retirer l'expertise.id si la checkbox est décochée
                setEXPERTISEtags(EXPERTISEtags.filter(id => id !== target.id));
            }
        }
    }

    function _get_odd(page) {
        getODD(20,page,(res)=>{
            // console.log(res);
            setODDList(res.data.odd);
        }, (e)=>{
            return false;
        });
    }

    function _get_project_odd(id, callback=()=>{}) {
        getPROJECT_ODD(id,(res)=>{
            let oddL = [];
            // console.log(res.data.odd);
            res.data.odd.map((odd) => {
                // console.log(odd);
                oddL.push(odd.id);
            })
            setODDtags(oddL);
            callback();
        }, (e)=>{
            return false;
        });
    }

    function _get_project_expertise(id, callback=()=>{}) {
        getPROJECT_Expertise(id,(res)=>{
            let expertiseL = [];
            // console.log(res.data);
            res.data.domaines.map((odd) => {
                // console.log(odd);
                expertiseL.push(odd.id);
            });
            setEXPERTISEtags(expertiseL);
            callback();
        }, (e)=>{
            return false;
        });
    }

    function _get_project_album(id, callback=()=>{}) {
        getPROJECT_ALBUM(id,(res)=>{
            console.log(res);
            setCurrentImages(res.data.medias);
            callback();
        }, (e)=>{
            return false;
        });
    }

    function _get_competences(page) {
        getCOMPETENCES(20,page,(res)=>{
            // console.log(res);
            setDomaineList(res.data.domaines_competences);
        }, (e)=>{
            return false;
        });
    }

    useEffect(() => {
        _get_odd(1);
        _get_competences(1);
        if(page) {
            _get_(page);
        } else {
            _get_(1);
        }
    }, [])

    return (
        <div className='faq'>
            <div className='faq_content'>
                <div className='faq_content_button'>
                    <button
                        onClick={(e)=>{
                            setActionType("add");
                            setODDtags([]);
                            setEXPERTISEtags([]);
                            setCurrentPROJECT({
                                titre: "",
                                nom: "",
                                courte_description: "",
                                description: "",
                                date_debut: "",
                                date_fin: "",
                                date_creation: new Date(),
                                statut: "En cours"
                            });
                        }}
                    >AJOUTER</button>
                </div>
                <br/>
                <div>
                    <table className='table-classic'>
                        <thead>
                            <tr>
                                <th>IMAGE<br/> DE GARDE</th>
                                <th>TITRE</th>
                                <th>SOMMAIRE</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                PROJECT!==null &&
                                PROJECT.projets.map((project, index)=>(
                                    <tr style={{
                                        background: index%2===0 ? "#00000005" : "transparent",
                                    }}>
                                        <td>
                                            <img src={path_.api_root+"fonctions/media/"+project.image_projet + "/"} alt='OREDS'/>
                                        </td>
                                        <td><strong>{project.titre}</strong></td>
                                        <td><div className='content' dangerouslySetInnerHTML={{__html: project.courte_description}}></div></td>
                                        {/* <td><strong>{project.status}</strong></td> */}
                                        <td>
                                            <button className='update'
                                                onClick={(e)=>{
                                                    setActionType("update");
                                                    _get_project_odd(project.id, ()=>{
                                                        _get_project_expertise(project.id, ()=>{
                                                            _get_project_album(project.id, ()=>{
                                                                setCurrentPROJECT(project);
                                                            });
                                                        });
                                                    });
                                                }}
                                            >
                                                Modifier
                                            </button>
                                            <button className='delete'
                                                onClick={(e)=>{
                                                    _delete_(project.id);
                                                }}
                                            >
                                                supprimer
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <br/>
                    <br/>

                    {
                        PROJECT!==null &&
                        <Box
                            sx={{
                                margin: "auto",
                                width: "fit-content",
                                alignItems: "center",
                            }}
                        >
                            <Pagination count={PROJECT.totalPage} page={PROJECT.page} 
                                onChange={(e, value)=>{
                                    _get_(value);
                                    window.history.replaceState({}, '', '/dashboard/admin/actuality/' + value);
                                }} variant="outlined" color="primary" />
                        </Box>
                    }
                    
                </div>
            {
                currentPROJECT !== null ?
                    <section className='information'>
                        <div className='information_content'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setCurrentPROJECT(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="information_content_head">
                                <div className="information_content_head_id"></div>
                            </div>

                            <form action='#' method='POST' className='form' name="projectForm"
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                    if(actionType==="add") {
                                        _add_();
                                    } else {
                                        _update_();
                                    }
                                }}
                            >
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>IMAGE DE GARDE</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input name='file' type='file' accept='image/*'/>
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Le titre du projet</span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>TITRE</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={currentPROJECT.titre?currentPROJECT.titre:""} name='name' required placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setCurrentPROJECT((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "titre": e.target.value
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Le titre du projet</span>
                                    </div>
                                </div>

                                <div style={{display: "flex"}}>
                                    <div className='form_input' style={{width: "100%", marginRight: "10px"}}>
                                        <div className='form_input_title'>
                                            <span>DATE DE DÉBUT</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input value={currentPROJECT.date_debut?currentPROJECT.date_debut:""} name='name' required placeholder='...' type='date'
                                                onChange={(e)=>{
                                                    setCurrentPROJECT((prev)=>{
                                                        return({
                                                            ...prev,
                                                            "date_debut": e.target.value
                                                        });
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='form_input_subText'>
                                            <span>La date de debut du projet</span>
                                        </div>
                                    </div>
                                    <div className='form_input' style={{width: "100%", marginLeft: "10px"}}>
                                        <div className='form_input_title'>
                                            <span>DATE DE FIN</span>
                                        </div>
                                        <div className='form_input_balise'>
                                            <input value={currentPROJECT.date_fin?currentPROJECT.date_fin:""} name='name' required placeholder='...' type='date'
                                                onChange={(e)=>{
                                                    setCurrentPROJECT((prev)=>{
                                                        return({
                                                            ...prev,
                                                            "date_fin": e.target.value
                                                        });
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='form_input_subText'>
                                            <span>La date de fin du projet</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='dashboard_body_input'>
                                    <div className='dashboard_body_input_label'>
                                        <span>
                                            Domaine de compétences associé
                                        </span>
                                    </div>
                                    <div className='dashboard_body_input_checkBox'>
                                        {
                                            domaineList.map((expertise, index) => (
                                                <label key={index} for={'dc' + index} className='dashboard_body_input_checkBox_'>
                                                    <input id={'dc' + index} name={'dc' + index} type='checkbox'
                                                        onChange={(e)=>{
                                                            isTarget( e.target.checked, expertise, "expertise" );
                                                        }}

                                                        checked={EXPERTISEtags.includes(expertise.id)}
                                                    />
                                                    <label for={'dc' + index}>
                                                        {expertise.nom}
                                                    </label>
                                                    <div className='dashboard_body_input_checkBox_info'>
                                                        <div className='dashboard_body_input_checkBox_info_'>
                                                            <div className='dashboard_body_input_checkBox_info_title'>
                                                                <span>
                                                                    {expertise.nom}
                                                                </span>
                                                            </div>
                                                            <div dangerouslySetInnerHTML={{__html: expertise.description}}></div>
                                                        </div>
                                                    </div>
                                                </label>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className='dashboard_body_input'>
                                    <div className='dashboard_body_input_label'>
                                        <span>
                                            Objectif de développement associé
                                        </span>
                                    </div>
                                    <div className='dashboard_body_input_checkBox'>
                                        {
                                            ODDList.map((odd, index) => (
                                                <label key={index} for={'odd' + index} className='dashboard_body_input_checkBox__'>
                                                    <input id={'odd' + index} name={'odd' + index} type='checkbox'
                                                        onChange={(e)=>{
                                                            isTarget( e.target.checked, odd, "odd" );
                                                        }}
                                                        checked={ODDtags.includes(odd.id)}
                                                    />
                                                    <label for={'odd' + index}>
                                                        <img className='dashboard_body_input_checkBox_image' src={"/ODD/odd" + odd.numero + ".png"} alt={"ODD"} />
                                                    </label>

                                                    <div className='dashboard_body_input_checkBox_info'>
                                                        <div className='dashboard_body_input_checkBox_info_'>
                                                            <div className='dashboard_body_input_checkBox_info_title'>
                                                                <span>
                                                                    {odd.nom}
                                                                </span>
                                                            </div>
                                                            <div dangerouslySetInnerHTML={{__html: odd.description}}></div>
                                                        </div>
                                                    </div>
                                                </label>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>SOMMAIRE</span>
                                    </div>
                                    <div className='reactQuillEditor'>
                                        <ReactQuill theme="snow" value={currentPROJECT.courte_description?currentPROJECT.courte_description:""} onChange={(value) => {
                                            setCurrentPROJECT((prev)=>{
                                                return({
                                                    ...prev,
                                                    "courte_description": value
                                                });
                                            })
                                        }} />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Une courte description du projet</span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>DESCRIPTION</span>
                                    </div>
                                    <div className='reactQuillEditor'>
                                        <ReactQuill theme="snow" value={currentPROJECT.description?currentPROJECT.description:""} onChange={(value) => {
                                            setCurrentPROJECT((prev)=>{
                                                return({
                                                    ...prev,
                                                    "description": value
                                                });
                                            })
                                        }} />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>La description du projet</span>
                                    </div>
                                </div>

                                {
                                    currentImages.length>0 &&
                                    <div className='form_input'>
                                        <div className='form_input_title'>
                                            <span>Liste des images</span> <button className='delete' style={{backgroundColor: "red"}} onClick={(e)=>{
                                                e.preventDefault();
                                                let image_ids = [];
                                                for(let i=0; i<currentImages.length; i++) {
                                                    image_ids.push(currentImages[i].id);
                                                }
                                                let data = {
                                                    image_ids: image_ids
                                                };
                                            
                                                _delete_album(currentPROJECT.id, data);
                                            }}>Tout supprimer</button>
                                        </div>
                                        <div className='form_input_images'>
                                            {
                                                currentImages.map((image, index) => (
                                                    <div className='form_input_images_content'>
                                                        <img src={path_.api_root+ "fonctions/" + image.image_url + "/"} alt=''/>
                                                        <div className='form_input_images_content_trash'>
                                                            <button
                                                                onClick={(e)=>{
                                                                    e.preventDefault();
                                                                    let data = {
                                                                        image_ids : [image.id]
                                                                    };
                                                                    // console.log(data);
                                                                    _delete_album(currentPROJECT.id, data);
                                                                }}
                                                            >
                                                                <span className='fa fa-trash'></span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }

                                <div className='add_content'>
                                    <div className='add_content_label'>
                                        <span>
                                            Ajoutez des images
                                        </span>
                                    </div>
                                    <div className='add_content_imageSelector'>
                                        <ImageSelector
                                            onChangeImage={async (e) => {
                                                const files = Array.from(e);
                                                let filesSelected_ = filesSelected;
                                                filesSelected_ = filesSelected_.concat(files);
                                                let tab = ImageSelectedPreviewLink;
                                                Promise.all(files.map(file => {
                                                    return (
                                                        new Promise((resolve, reject) => {
                                                            const reader = new FileReader();
                                                            reader.addEventListener('load', (ev) => {
                                                                resolve(ev.target.result);
                                                            });
                                                            reader.addEventListener('error', reject);
                                                            reader.readAsDataURL(file);
                                                        })
                                                    )
                                                })).then(images => {
                                                    tab = tab.concat(images);
                                                    setFilesSelected(filesSelected_);
                                                    setImageSelectedPreviewLink(tab);
                                                });

                                            }}
                                        />
                                    </div>

                                    

                                    {
                                        ImageSelectedPreviewLink.length > 0 &&
                                        <div className='delete_all_image'>
                                            <span className='delete_all_image_text' onClick={() => {
                                                _deleteAllImages();
                                            }}>
                                                <span>
                                                    Supprimer toutes les images
                                                </span>
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                </svg>
                                            </span>
                                        </div>
                                    }

                                    

                                    <div className="previewImageSelected_container">
                                        {
                                            ImageSelectedPreviewLink.map((link, index) => (
                                                <div className='previewImageContainer'>
                                                    <div className="previewImageSelected_">
                                                        <svg onClick={() => {
                                                            _deleteImage(index);
                                                        }} className='previewImageSelected_cross' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529" />
                                                            <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529" />
                                                        </svg>
                                                        <img className="previewImageSelected" key={index} src={link} alt="RAD-ONG" />
                                                    </div>
                                                    {/* <div className='imageDescription'>
                                                        <input type="text" placeholder="Description de l'image ici" value={ImageSelectedList[index].description}
                                                            onChange={(e) => {
                                                                let ImageSelectedList_ = ImageSelectedList;
                                                                ImageSelectedList_[index].description = e.target.value;

                                                                setImageSelectedList(ImageSelectedList_);

                                                            }}
                                                        />
                                                    </div> */}
                                                </div>
                                            ))
                                        }
                                    </div>

                                    <br/>
                                    <br/>
                                </div>

                                <div className='form_button'>
                                    <button>
                                        {actionType==="add" ? "Ajouter" : "Modifier"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </section>
                :
                    null
            }
            </div>
        </div>
    );
}

export default DashboardAdminProject;