import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();

class ODDRequest {
    add(data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "fonctions/odd/ajouter/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetAll(limit = 20, page = 1) {
        return (
            axios({
                method: 'get',
                // url: PATH__.api_root + "functions/odd/liste/?limit=" + limit + "&page=" + page,
                url: PATH__.api_root + "fonctions/odd/liste/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetOne(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "fonctions/odd/detail/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetActualitiesOrProjects(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "fonctions/projets-actualites-par-odd/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    update(id, data) {
        return (
            axios({
                method: 'put',
                data: data,
                url: PATH__.api_root + "fonctions/odd/modifier/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    delete(id) {
        return (
            axios({
                method: 'delete',
                url: PATH__.api_root + "fonctions/odd/supprimer/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }
}

export default ODDRequest;