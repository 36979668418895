import * as React from "react";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

/**Importation des vues */
import Home from "../screens/home.screen";
import LoginView from '../screens/login.screen';
import RegistrationView from '../screens/registration.screen';
import AboutUs from "../screens/aboutUs.screen";
import Faq from "../screens/faq.screen";
import Projects from "../screens/projects.screen";
import Donate from "../screens/donate.screen";
import ContactUs from "../screens/contactUS.screen";
import NewsView from "../screens/news.screen";
import OneNewsView from "../screens/one_new.screen";
import OneProjectView from "../screens/one_project.screen";
import OneODDView from "../screens/odd.screen";
import OneCompetenceView from "../screens/competence.screen";

import ErrorPage from "../screens/error.screen";

import DashboardAdmin from "../screens/dashboard.admin.screen";
import DashboardAdminODD from "../screens/dashboard.admin.odd.screen";
import DashboardAdminCategory from "../screens/dashboard.admin.category.screen";
import DashboardAdminActuality from "../screens/dashboard.admin.actuality.screen";
import DashboardAdminArticle from "../screens/dashboard.admin.article.screen";
import DashboardAdminProject from "../screens/dashboard.admin.project.screen";
import DashboardAdminCompetences from "../screens/dashboard.admin.competences.screen";
import DashboardAdminDonate from "../screens/dashboard.admin.donate.screen";
// import LoginView from "../screens/login.screen";


const router_ = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        children: [
            {
                path: "/about-us",
                element: <AboutUs/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/login",
                element: <LoginView/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/registration",
                element: <RegistrationView/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/faq",
                element: <Faq/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/faq/:current",
                element: <Faq/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/projects/:type",
                element: <Projects/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/project/:current",
                element: <OneProjectView/>,
                errorElement: <ErrorPage/>,
            },{
                path: "donate",
                element: <Donate/>
            },{
                path: "contact",
                element: <ContactUs/>
            },{
                path: "news",
                element: <NewsView/>
            },{
                path: "/news/:current",
                element: <OneNewsView/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/ODD/:odd",
                element: <OneODDView/>,
                errorElement: <ErrorPage/>,
            },{
                path: "/competence/:competence",
                element: <OneCompetenceView/>,
                errorElement: <ErrorPage/>,
            }
        ],
        errorElement: <ErrorPage/>,
    },{
        path: "/dashboard/admin/",
        element: <DashboardAdmin/>,
        children: [{
            path: "odd",
            element: <DashboardAdminODD/>
        },{
            path: "odd/:page",
            element: <DashboardAdminODD/>
        },{
            path: "competences",
            element: <DashboardAdminCompetences/>
        },{
            path: "competences/:page",
            element: <DashboardAdminCompetences/>
        },{
            path: "category",
            element: <DashboardAdminCategory/>
        },{
            path: "category/:page",
            element: <DashboardAdminCategory/>
        },{
            path: "actuality",
            element: <DashboardAdminActuality/>
        },{
            path: "actuality/:page",
            element: <DashboardAdminActuality/>
        },{
            path: "article",
            element: <DashboardAdminArticle/>
        },{
            path: "article/:page",
            element: <DashboardAdminArticle/>
        },{
            path: "project",
            element: <DashboardAdminProject/>
        },{
            path: "project/:page",
            element: <DashboardAdminProject/>
        },{
            path: "donates",
            element: <DashboardAdminDonate/>
        },{
            path: "donates/:page",
            element: <DashboardAdminDonate/>
        },{
            path: "login",
            element: <LoginView/>
        }]
    }
]);

const TEST = false;

function Route() {
    if(TEST) {
        return (
            <RouterProvider router={router_}/>
        );
    } else {
        return (
            <RouterProvider router={router_}/>
        );
    }
}
  
export default Route;