import React, {useState, useContext, useEffect} from 'react';
import {
    useParams
} from "react-router-dom";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";

import '../styles/dashboard.faq.style.scss';

import { ContextP } from "../context/provider";

function DashboardAdminODD( props ) {
    const { page } = useParams();

    const {getODD} = useContext(ContextP);
    const {addODD} = useContext(ContextP);
    const {updateODD} = useContext(ContextP);
    const {deleteODD} = useContext(ContextP);

    // const {setALertMessage} = useContext(ContextP);
    // const {setCurrentAlertMessage} = useContext(ContextP);
    // const {setCurrentAlertType} = useContext(ContextP);
    // const {setCurrentAlertAction} = useContext(ContextP);


    const [ODD, setODD] = useState(null);
    const [actionType, setActionType] = useState("add");
    const [currentODD, setCurrentODD] = useState(null);

    function _add_() {
        console.log(currentODD);
        addODD(currentODD, (res)=>{
            setCurrentODD(null);
            if(page) {
                _get_(page);
            } else {
                _get_(1);
            }
        }, (e)=>{
            console.log(e);
        });
    }

    function _update_() {
        if(window.confirm("Voulez-vous vraiment modifier cette question ?")) {
            updateODD(currentODD.id, currentODD, (res)=>{
                setCurrentODD(null);
                if(page) {
                    _get_(page);
                } else {
                    _get_(1);
                }
            }, (e)=>{
                console.log(e);
            })
        }
    }

    function _delete_(id) {
        // console.log(id);
        if(window.confirm("Voulez-vous vraiment supprimer cette question ?")) {
            deleteODD(id, (res)=>{
                setCurrentODD(null);
                // setALertMessage(false);
                if(page) {
                    _get_(page);
                } else {
                    _get_(1);
                }
            }, (e)=>{
                console.log(e);
            })
        }
    }

    function _get_(page) {
        getODD(20,page,(res)=>{
            console.log(res);
            setODD(res.data);
        }, (e)=>{
            return false;
        });
    }

    useEffect(() => {
        if(page) {
            _get_(page);
        } else {
            _get_(1);
        }
    }, [setODD])

    return (
        <div className='faq'>
            <div className='faq_content'>
                <div className='faq_content_button'>
                    <button
                        onClick={(e)=>{
                            setCurrentODD({
                                "nom": "",
                                "lien": "",
                                "numero": "",
                                "description": ""
                            });
                        }}
                    >AJOUTER</button>
                </div>
                <br/>
                <div>
                    <table className='table-classic'>
                        <thead>
                            <tr>
                                <th>NUMÉRO</th>
                                <th>LOGO</th>
                                <th>NOM</th>
                                <th>LIEN</th>
                                <th>DESCRIPTION</th>
                                <th>ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ODD!==null &&
                                ODD.odd.map((odd, index)=>(
                                    <tr style={{
                                        background: index%2===0 ? "#00000005" : "transparent",
                                    }}>
                                        <td><strong>{odd.numero}</strong></td>
                                        <td><img src={"/ODD/odd" + odd.numero + ".png"} alt=''/></td>
                                        <td><strong>{odd.nom}</strong></td>
                                        <td><strong>{odd.lien}</strong></td>
                                        <td><div className='content' dangerouslySetInnerHTML={{__html: odd.description}}></div></td>
                                        <td>
                                            <button className='update'
                                                onClick={(e)=>{
                                                    setActionType("update");
                                                    setCurrentODD(odd);
                                                }}
                                            >
                                                Modifier
                                            </button>
                                            <button className='delete'
                                                onClick={(e)=>{
                                                    _delete_(odd.id);
                                                }}
                                            >
                                                supprimer
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <br/>
                    <br/>

                    {
                        ODD!==null &&
                        <Box
                            sx={{
                                margin: "auto",
                                width: "fit-content",
                                alignItems: "center",
                            }}
                        >
                            <Pagination count={ODD.totalPage} page={ODD.page} 
                                onChange={(e, value)=>{
                                    _get_(value);
                                    window.history.replaceState({}, '', '/dashboard/admin/faq/' + value);
                                }} variant="outlined" color="primary" />
                        </Box>
                    }
                    
                </div>
            {
                currentODD !== null ?
                    <section className='information'>
                        <div className='information_content'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setCurrentODD(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="information_content_head">
                                <div className="information_content_head_id"></div>
                            </div>

                            <form action='#' method='POST' className='form'
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                    if(actionType==="add") {
                                        _add_();
                                    } else {
                                        _update_();
                                    }
                                }}
                            >
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>NUMÉRO</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={currentODD.numero?currentODD.numero:""} name='name' required placeholder='...' type='number'
                                            onChange={(e)=>{
                                                setCurrentODD((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "numero": Number(e.target.value)
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Le numero de l'ODD</span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>NOM</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={currentODD.nom?currentODD.nom:""} name='name' required placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setCurrentODD((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "nom": e.target.value
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Le nom de l'ODD</span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>LIEN</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={currentODD.lien?currentODD.lien:""} name='name' required placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setCurrentODD((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "lien": e.target.value
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span></span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>DESCRIPTION</span>
                                    </div>
                                    <div className='reactQuillEditor'>
                                        <ReactQuill theme="snow" value={currentODD.description?currentODD.description:""} onChange={(value) => {
                                            setCurrentODD((prev)=>{
                                                return({
                                                    ...prev,
                                                    "description": value
                                                });
                                            })
                                        }} />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Le lien vers la page de l'ODD</span>
                                    </div>
                                </div>

                                <div className='form_button'>
                                    <button>
                                        Valider
                                    </button>
                                </div>
                            </form>
                        </div>
                    </section>
                :
                    null
            }
            </div>
        </div>
    );
}

export default DashboardAdminODD;