// import React, { useContext , useEffect} from 'react';

import React, {useEffect, useState, useContext} from 'react';

// import {
//     useParams
// } from "react-router-dom";

// import { ContextP } from "../context/provider";

import '../styles/aboutUs.screen.style.scss';
import '../styles/donate.screen.style.scss';

/**Importation des images */

import { ContextP } from "../context/provider";

function Donate( props ) {
    const {addDONATE} = useContext(ContextP);
    const {getPROJECT} = useContext(ContextP);

    // const { type } = useParams();

    // const [TYPE, setTYPE] = useState(null);

    const [amount, setAmount] = useState('');
    // const [frequency, setFrequency] = useState('once');
    const [project, setProject] = useState('');
    const [projects, setProjects] = useState([]);
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [enable, setEnable] = useState(false);

    function _get_(page) {
        getPROJECT(20,page,(res)=>{
            // console.log(res);
            setProjects(res.data.projets);
        }, (e)=>{
            return false;
        });
    }

    function _add_() {
        let data = {
            "nom": lastName,
            "prenom": firstName,
            "projet_id": project,
            "montant": amount,
            "accords_value": enable
        };
        console.log(data);
        addDONATE(data, (res)=>{
            // console.log(res);
            setAmount("");
            setProject("");
            setLastName("");
            setFirstName("");
            setEnable(false);
            if (res.data.whatsapp_url) {
                window.location.href = res.data.whatsapp_url;
            }
        }, (e)=>{
            console.log(e);
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        _add_();
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const handleProjectChange = (e) => {
        setProject(e.target.value);
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleEnableChange = (e) => {
        setEnable(e.target.value);
    };

    useEffect(() => {
        _get_();
    }, [])
    
    return (
        <div className='AboutUsContent donation-page'>
            <div className='AboutUsContent_section'>
                <div className='AboutUsContent_section_back'></div>
                <div className='AboutUsContent_section_content'>
                    <div className='AboutUsContent_section_content_new'>
                        <div>
                            <h2>
                                Faire un don
                            </h2>
                            <p>Soutenez nos actions en faveur des plus démunis.</p>
                        </div>
                        <div className='AboutUsContent_section_content_new_info'>
                            <a href='/contact' className='button-style-3'>
                                NOUS CONTACTER
                            </a>
                        </div>
                    </div>
                    <div className='AboutUsContent_section_content_form'>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="amount">Montant du don</label>
                                <input
                                type="number"
                                id="amount"
                                name="amount"
                                value={amount}
                                onChange={handleAmountChange}
                                required
                                />
                            </div>
                            {/* <div className="form-group">
                                <label htmlFor="frequency">Fréquence du don</label>
                                <select
                                id="frequency"
                                name="frequency"
                                value={frequency}
                                onChange={handleFrequencyChange}
                                required
                                >
                                <option value="once">Ponctuel</option>
                                <option value="monthly">Mensuel</option>
                                <option value="quarterly">Trimestriel</option>
                                <option value="yearly">Annuel</option>
                                </select>
                            </div> */}
                            <div className="form-group">
                                <label htmlFor="project">Destination du don</label>
                                <select
                                    id="project"
                                    name="project"
                                    value={project}
                                    onChange={handleProjectChange}
                                    
                                >
                                    <option value="">Choisir un projet</option>
                                    {
                                        projects.map((proj, index)=>(
                                            <option value={proj.id}>{proj.nom}</option>
                                        ))
                                    }
                                    {/* <option value="project1">Projet 1</option>
                                    <option value="project2">Projet 2</option>
                                    <option value="project3">Projet 3</option> */}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastName">Nom</label>
                                <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={lastName}
                                onChange={handleLastNameChange}
                                required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="firstName">Prénom</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={firstName}
                                    onChange={handleFirstNameChange}
                                    required
                                />
                            </div>

                            <div className="box">
                                <label htmlFor="enable">Souhaitez-vous être annonyme ?</label>
                                <input
                                    type="checkbox"
                                    id="enable"
                                    name="enable"
                                    // value={enable}
                                    checked={enable}
                                    onChange={(e)=>{
                                        setEnable(e.target.checked);
                                    }}
                                    
                                />
                            </div>
                            
                            <br/>
                            <button type="submit">Faire un don</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Donate;