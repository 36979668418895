import React, {useState, useContext, useEffect} from 'react';
import {
    useParams
} from "react-router-dom";
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

import ImageSelector from '../components/imageSelector/imageSelector.component';

import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";

import '../styles/dashboard.faq.style.scss';

import PATH_ from '../GLOBAL-VAR/globals-urls';

import { ContextP } from "../context/provider";
function DashboardAdminDonate( props ) {

    // const { page } = useParams();

    const {getConfirmDONATE} = useContext(ContextP);
    const {getUnConfirmDONATE} = useContext(ContextP);
    const {confirmDONATE} = useContext(ContextP);


    const [DonateConfirmed, setDonateConfirmed] = useState(null);
    const [DonateUnconfirmed, setDonateUnconfirmed] = useState(null);
    
    
    function _get_confirm(page) {
        getConfirmDONATE((res)=>{
            // console.log(res);
            setDonateConfirmed(res.data.dons_confirmes);
        }, (e)=>{
            return false;
        });
    }

    function _get_unConfirm(page) {
        getUnConfirmDONATE((res)=>{
            // console.log(res);
            setDonateUnconfirmed(res.data.dons_non_confirmes);
        }, (e)=>{
            return false;
        });
    }

    function Confirm_donate(id) {
        confirmDONATE(id, (res)=>{
            _get_confirm(1);
            _get_unConfirm(1);
        }, (e)=>{
            return false;
        });
    }

    useEffect(() => {
        _get_confirm(1);
        _get_unConfirm(1);
    }, [])

    return (
        <div className='faq'>
            <div className='faq_content'>
                <br/>
                <div>
                    <table className='table-classic'>
                        <thead>
                            <tr>
                                <th>MONTANT</th>
                                <th>DONATEUR</th>
                                <th>STATUT</th>
                                <th>PROJET</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                DonateConfirmed!==null &&
                                DonateConfirmed.map((donate, index)=>(
                                    <tr style={{
                                        background: index%2===0 ? "#00000005" : "transparent",
                                    }}>
                                        <td>
                                            <strong>{donate.montant}</strong>
                                        </td>
                                        <td><strong>{donate.nom}</strong> <strong>{donate.prenom}</strong></td>
                                        <td>
                                            <strong>{donate.projet}</strong>
                                        </td>
                                        <td>
                                            <span style={{
                                                color: "green"
                                            }}>
                                                Confirmé
                                            </span>
                                        </td>
                                        <td>
                                            
                                        </td>
                                    </tr>
                                ))
                            }

                            {
                                DonateUnconfirmed!==null &&
                                DonateUnconfirmed.map((donate, index)=>(
                                    <tr style={{
                                        background: index%2===0 ? "#00000005" : "transparent",
                                    }}>
                                        <td>
                                            <strong>{donate.montant}</strong>
                                        </td>
                                        <td><strong>{donate.nom}</strong> <strong>{donate.prenom}</strong></td>
                                        <td>
                                            <strong>{donate.projet}</strong>
                                        </td>
                                        <td>
                                            <span style={{
                                                color: "orange"
                                            }}>
                                                En attente de confirmation
                                            </span>
                                        </td>
                                        <td>
                                            <button className='view'
                                                onClick={(e)=>{
                                                    Confirm_donate(donate.id);
                                                }}
                                            >
                                                Confirmer
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                </div>
            </div>
        </div>
    );
}

export default DashboardAdminDonate;