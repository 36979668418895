// import React, {useState, useEffect} from 'react';
import React, {useEffect, useState} from 'react';
import { Route } from 'react-router';
import { Routes } from 'react-router-dom';

import HomeView from './homeView.screen';
import AboutUs from './aboutUs.screen';
import LoginView from './login.screen';
import RegistrationView from './registration.screen';
import Faq from './faq.screen';
import NewsView from './news.screen';
import OneNewsView from './one_new.screen';
import OneProjectView from './one_project.screen';
import Projects from './projects.screen';
import Donate from './donate.screen';
import ContactUs from './contactUS.screen';
import OneODDView from './odd.screen';
import OneCompetenceView from './competence.screen';

/**Importation des composants */
import MenuComponent from '../components/menu/menu.component';

import '../styles/home.screen.style.scss';

/**Importation des helpers */
// import FixedOnScrollPage from '../helpers/fixe_to_head.helper';

function Home( props ) {

    const [isHidden, setIsHidden] = useState(false);
    function handleScroll() {
        const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (currentScrollTop > 50) {
            // Scrolling down and passed the threshold
            setIsHidden(true);
        } else {
            // Scrolling up and passed the threshold
            setIsHidden(false);
        }
    }

    useEffect(()=>{
        window.addEventListener('scroll', handleScroll);
    });

    const list_menu = [{
        icon: <i className="fa fa-home menuIcon"></i>,
        name: "Accueil",
        link: "/",
        action: (callback) => {
            callback();
            return true;
        },
        // subMenus: [{
        //         icon: null,
        //         name: "Fiscalité",
        //         link: "##",
        //     },
        //     {
        //         icon: null,
        //         name: "Impôts",
        //         link: "##",
        //     },
        //     {
        //         icon: null,
        //         name: "Douane",
        //         link: "##",
        //     },
        // ]
    },
    {
        icon: <i style={{fontSize: "0.8em"}} className="fa fa-users menuIcon"></i>,
        name: "À propos de nous",
        link: "/about-us",
        action: (callback) => {
            callback();
            return true;
        },
    },
    {
        icon: <i style={{marginRight: "10px"}} className="fa fa-newspaper-o menuIcon"></i>,
        name: "À la une",
        link: "/news",
        action: (callback) => {
            callback();
            return true;
        },
    },{
        icon: <i className="fa fa-thumb-tack menuIcon"></i>,
        name: "Nos projets",
        link: "/projects/en cours",
        action: (callback) => {
            callback();
            return true;
        },
    },{
        icon: <i className="fa fa-phone menuIcon menuIcon2"></i>,
        name: "Nous contacter",
        link: "/contact",
        action: (callback) => {
            callback();
            return true;
        },
    }];
    // useEffect(() => {
    //     const currentUser = JSON.parse(localStorage.getItem("user"));
    // },[]);
    return (
        <>
            <header>
                <nav className={"nav top_nav " + (isHidden ? " is_hidden" : " is_visible")}>
                    <div>
                        <ul>
                            <li>
                                <a className='home' href='/'>
                                    <span>O</span>
                                    <span>R</span>
                                    <span>E</span>
                                    <span>D</span>
                                    <span>S</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul className='top_nav_connection'>
                            {/* <li>
                                <a className='button-style-1 connection' href='/login'>
                                    SE CONNECTER
                                </a>
                            </li>
                            <li>
                                <a className='button-style-2 search' href='##'>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0392 15.6244C18.2714 14.084 19.0082 12.1301 19.0082 10.0041C19.0082 5.03127 14.9769 1 10.0041 1C5.03127 1 1 5.03127 1 10.0041C1 14.9769 5.03127 19.0082 10.0041 19.0082C12.1301 19.0082 14.084 18.2714 15.6244 17.0392L21.2921 22.707C21.6828 23.0977 22.3163 23.0977 22.707 22.707C23.0977 22.3163 23.0977 21.6828 22.707 21.2921L17.0392 15.6244ZM10.0041 17.0173C6.1308 17.0173 2.99087 13.8774 2.99087 10.0041C2.99087 6.1308 6.1308 2.99087 10.0041 2.99087C13.8774 2.99087 17.0173 6.1308 17.0173 10.0041C17.0173 13.8774 13.8774 17.0173 10.0041 17.0173Z" fill="currentColor"/>
                                    </svg>
                                </a>
                            </li> */}
                        </ul>
                    </div>
                </nav>
                <div className="nav kando-header bottom_nav">
                    <div></div>
                    <MenuComponent list_menu={list_menu} />
                </div>
            </header>
            <Routes>
                <Route path='/login' element={<LoginView/>}/>
                <Route path='/registration' element={<RegistrationView/>}/>
                <Route path='/about-us' element={<AboutUs/>}/>
                
                <Route path='/faq' element={<Faq/>}/>
                <Route path='/faq/:current' element={<Faq/>}/>
                
                <Route path='/news' element={<NewsView/>}/>
                <Route path='/news/:current' element={<OneNewsView/>}/>

                <Route path='/projects/:type' element={<Projects/>}/>
                <Route path='/project/:current' element={<OneProjectView/>}/>

                <Route path='/donate' element={<Donate/>}/>
                <Route path='/contact' element={<ContactUs/>}/>

                <Route path='/ODD/:odd' element={<OneODDView/>}/>
                <Route path='/competence/:competence' element={<OneCompetenceView/>}/>

                {/* <Route path='/news' element={<NewsView/>}/> */}

                <Route path='*' element={<HomeView/>}/>
            </Routes>
            <footer className="footer">
                <div className="site-footer__upper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="footer-widget footer-widget__about">
                                    <p></p>
                                    <div className="footer-widget__social">
                                        {/* <img src="/rad-logo.png" alt='RAD-ONG'/> */}
                                        <a href="./" className="nav_icon">
                                            <div className="nav_icon_"></div>
                                            <div className="nav_icon_text">
                                                <div className="nav_icon_text_name">
                                                    <ul>
                                                        <li>
                                                            <a className='home' href='/'>
                                                                <span>O</span>
                                                                <span>R</span>
                                                                <span>E</span>
                                                                <span>D</span>
                                                                <span>S</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </a>
                                        <div className="nav_icon_text_slug">
                                            <span>
                                                Siège social: Département de l'atlantique, commune d'Abomey-Calavi, Arrondissement de Godomey, quartier Atropocodji, Carré sans borne, maison Marie-Madeleine, BP : 91 Parakou.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="footer-widget footer-widget__links__2">
                                    <h3 className="footer-widget__title">Liens utiles</h3>
                                    <ul className="list-unstyled footer-widget__links-list">
                                        <li><a href="##"><span className="fa fa-chevron-right mr-2"></span>Accueil</a></li>
                                        <li><a href="##"><span className="fa fa-chevron-right mr-2"></span>À propos de nous</a></li>                                            
                                        <li><a href="/about-us"><span className="fa fa-chevron-right mr-2"></span>À la une</a></li>
                                        <li><a href="##"><span className="fa fa-chevron-right mr-2"></span>Nos projets</a></li>
                                        <li><a href="/contact"><span className="fa fa-chevron-right mr-2"></span>Nous contacter</a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="footer-widget footer-widget__links__2">
                                    <ul className="list-unstyled footer-widget__links-list">
                                        <li><a href="##"><span className="fa fa-chevron-right mr-2"></span>Mentions légales</a></li>
                                        <li><a href="##"><span className="fa fa-chevron-right mr-2"></span>Politique de confidentialité</a></li>                                            
                                        <li><a href="##"><span className="fa fa-chevron-right mr-2"></span>Conditions Générales de Ventes</a></li>
                                        <li><a href="##"><span className="fa fa-chevron-right mr-2"></span>Règles Générales de Protection des Données</a></li>
                                    </ul>
                                </div>
                            </div> */}
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="footer-contacts footer-widget footer-widget__contact">
                                    <h3 className="footer-widget__title">Contact</h3>
                                    <p><a href={"tel:"}><i className="fa fa-phone"></i>{ "+22997617615" }</a></p>
                                    <p><a href={"tel:"}><i className="fa fa-send"></i>{ "coranguerisom@gmail.com" }</a></p>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-footer__bottom">
                    <div className="container">
                        <p></p>
                        <span>
                            © 2024 <strong>OREDS</strong> .Tous droits réservés
                        </span>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Home;