import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();

class DonateRequest {
    add(data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "fonctions/faire-un-don/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetConfirm() {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "fonctions/dons/confirmes/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetUnConfirm() {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "fonctions/dons/non-confirmes/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    confirm(id) {
        return (
            axios({
                method: 'post',
                url: PATH__.api_root + "fonctions/dons/" + id + "/confirmer/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }


}

export default DonateRequest;