// import React, { useContext , useEffect} from 'react';

import React, {useEffect, useState} from 'react';

import {
    useParams
} from "react-router-dom";

// import { ContextP } from "../context/provider";

import '../styles/contactUs.screen.style.scss';
import '../styles/donate.screen.style.scss';

/**Importation des images */

function ContactUs( props ) {
    const { type } = useParams();

    const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: handle form submission (e.g., send data to server)
    setSubmitted(true);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };


    
    return (
        <div className=' contact-page'>
            <div className='AboutUsContent_section'>
                <div className='AboutUsContent_section_back'></div>
                <div className='AboutUsContent_section_content'>
                    <div className='AboutUsContent_section_content_new'>
                        <div>
                            <h2>
                                Contactez-nous
                            </h2>
                            <p>Nous sommes là pour répondre à vos questions et vous aider.</p>
                        </div>
                        <div className='AboutUsContent_section_content_new_info'>
                            {/* <a href='/contact' className='button-style-3'>
                                NOUS CONTACTER
                            </a> */}
                        </div>
                    </div>
                    <div className='AboutUsContent_section_content_form'>
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Nom</label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              value={name}
                              onChange={handleNameChange}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="email">Adresse email</label>
                            <input
                              type="email"
                              id="email"
                              name="email"
                              value={email}
                              onChange={handleEmailChange}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="subject">Sujet</label>
                            <input
                              type="text"
                              id="subject"
                              name="subject"
                              value={subject}
                              onChange={handleSubjectChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea
                              id="message"
                              name="message"
                              value={message}
                              onChange={handleMessageChange}
                              required
                            />
                          </div>
                          <button type="submit">Envoyer le message</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;