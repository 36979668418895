import { createContext, useState } from "react"

import loader from '../assets/gif/loader.gif';

import ODDRequest from "../helpers/odd.requests.helper";
import COMPETENCESRequest from "../helpers/competences.requests.helper";
import CategoryRequest from "../helpers/category.requests.helper";
import ArticleRequest from "../helpers/article.requests.helper";
import ProjectRequest from "../helpers/project.requests.helper";
import MediaRequest from "../helpers/media.requests.helper";
import DonateRequest from "../helpers/donate.requests.helper";
import AuthRequest from "../helpers/auth.requests.helper";

import Alert from '@mui/material/Alert';

export const ContextP = createContext();

const ODD = new ODDRequest();
const COMPETENCES = new COMPETENCESRequest();
const CATEGORY = new CategoryRequest();
const ARTICLE = new ArticleRequest();
const PROJECT = new ProjectRequest();
const MEDIA = new MediaRequest();
const DONATE = new DonateRequest();
const AUTH = new AuthRequest();

// début du composant
/**
 * Cette page sert de vue parente.
 * Tous les affichages globeaux, les fonctions globals seront deposer ici 
 * et heriter par toutes les autres pages
 */
export default function Provider({ children }) {
    /**state */
    const [isLoading, setIsLoading] = useState(false);
    const [ALertMessage, setALertMessage] = useState(false);
    const [currentAlertMessage, setCurrentAlertMessage] = useState("");
    const [currentAlertType, setCurrentAlertType] = useState("warning");
    const [currentAlertAction, setCurrentAlertAction] = useState({action: ()=>{}});
    
    /**Functions */
    /**---------------------------------AUTH------------------------------------- */
    const login = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        AUTH.login(data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    /**---------------------------------ODD------------------------------------- */
    const addODD = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ODD.add(data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const updateODD = function (id, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ODD.update(id, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const deleteODD = function (id, success=()=>{}, failed=()=>{}) {
        // console.log(id);
        setIsLoading(true);
        ODD.delete(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getODD = function (limit=20, page=1, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ODD.GetAll(limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getODDProjectsOrActualities = function (id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ODD.GetActualitiesOrProjects(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getOneODD = function (id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ODD.GetOne(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    /**Domaines de competences */
    const addCOMPETENCES = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        COMPETENCES.add(data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const updateCOMPETENCES = function (id, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        COMPETENCES.update(id, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getDomaineProjectsOrActualities = function (id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        COMPETENCES.GetActualitiesOrProjects(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getOneDomaine = function (id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        COMPETENCES.GetOne(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const deleteCOMPETENCES = function (id, success=()=>{}, failed=()=>{}) {
        // console.log(id);
        setIsLoading(true);
        COMPETENCES.delete(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getCOMPETENCES = function (limit=20, page=1, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        COMPETENCES.GetAll(limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }


    /**----------------------------------------------- */
    const addCATEGORY = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        CATEGORY.add(data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const updateCATEGORY = function (id, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        CATEGORY.update(id, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const deleteCATEGORY = function (id, success=()=>{}, failed=()=>{}) {
        // console.log(id);
        setIsLoading(true);
        CATEGORY.delete(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getCATEGORY = function (limit=20, page=1, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        CATEGORY.GetAll(limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }


    /**----------------------------------------------- */
    const addARTICLE = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.add(data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const addODDToARTICLE = function (articleId, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.addODD(articleId, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const addProjectToARTICLE = function (articleId, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.addProject(articleId, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getARTICLE_PROJECTS = function (articleId, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.GetPROJECTS(articleId).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const updateARTICLE_PROJECTS = function (articleId, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.updatePROJECTS(articleId, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const addAlbumToARTICLE = function (articleId, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.addAlbum(articleId, data).then((response) => {
            setIsLoading(false);
            if(response.status===200 || response.status===201) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getARTICLE_ALBUM = function (articleId, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.getAlbum(articleId).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const deleteARTICLE_ALBUM = function (articleId, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.deleteAlbum(articleId, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const updateARTICLE_ODD = function (articleId, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.updateODD(articleId, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getARTICLE_ODD = function (articleId, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.GetODD(articleId).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getARTICLE_Expertise = function (articleId, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.GetExpertise(articleId).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const addExpertiseToARTICLE = function (articleId, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.addExpertise(articleId, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const updateARTICLE_Expertise = function (articleId, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.updateExpertise(articleId, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const updateARTICLE = function (id, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.update(id, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const deleteARTICLE = function (id, success=()=>{}, failed=()=>{}) {
        // console.log(id);
        setIsLoading(true);
        ARTICLE.delete(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getARTICLE = function (limit=20, page=1, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.GetAll(limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getOneARTICLE = function (id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        ARTICLE.GetOne(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }


    /**----------------------------------------------- */
    const addPROJECT = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        PROJECT.add(data).then((response) => {
            setIsLoading(false);
            if(response.status===201) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const addODDToPROJECT = function (projectId, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        PROJECT.addODD(projectId, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const addAlbumToPROJECT = function (projectId, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        PROJECT.addAlbum(projectId, data).then((response) => {
            setIsLoading(false);
            if(response.status===200 || response.status===201) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getPROJECT_ALBUM = function (projectId, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        PROJECT.getAlbum(projectId).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const deletePROJECT_ALBUM = function (projectId, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        PROJECT.deleteAlbum(projectId, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const updatePROJECT_ODD = function (projectId, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        PROJECT.updateODD(projectId, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getPROJECT_ODD = function (projectId, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        PROJECT.GetODD(projectId).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getPROJECT_Expertise = function (projectId, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        PROJECT.GetExpertise(projectId).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const addExpertiseToPROJECT = function (projectId, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        PROJECT.addExpertise(projectId, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const updatePROJECT_Expertise = function (projectId, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        PROJECT.updateExpertise(projectId, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const updatePROJECT = function (id, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        PROJECT.update(id, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const deletePROJECT = function (id, success=()=>{}, failed=()=>{}) {
        // console.log(id);
        setIsLoading(true);
        PROJECT.delete(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getPROJECT = function (limit=20, page=1, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        PROJECT.GetAll(limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getOnePROJECT = function (id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        PROJECT.GetOne(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    /**----------------------------------------------- */
    const addMEDIA = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        MEDIA.add(data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                // setCurrentAlertMessage("SUCCESS");
                // setCurrentAlertType("success");
                // setALertMessage(true);
                // setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const updateMEDIA = function (id, data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        MEDIA.update(id, data).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const deleteMEDIA = function (id, success=()=>{}, failed=()=>{}) {
        // console.log(id);
        setIsLoading(true);
        MEDIA.delete(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getMEDIA = function (limit=20, page=1, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        MEDIA.GetAll(limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    /**------------------------------------------------------------------------------------------------------- */
    const addDONATE = function (data, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        DONATE.add(data).then((response) => {
            setIsLoading(false);
            // console.log(response);
            if(response.status===201) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getConfirmDONATE = function (success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        DONATE.GetConfirm().then((response) => {
            setIsLoading(false);
            // console.log(response);
            if(response.status===200) {
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getUnConfirmDONATE = function (success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        DONATE.GetUnConfirm().then((response) => {
            setIsLoading(false);
            // console.log(response);
            if(response.status===200) {
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const confirmDONATE = function (id, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        DONATE.confirm(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                
                setCurrentAlertAction({action: ()=>{}});
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const deleteDONATE = function (id, success=()=>{}, failed=()=>{}) {
        // console.log(id);
        setIsLoading(true);
        DONATE.delete(id).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                setCurrentAlertMessage("SUCCESS");
                setCurrentAlertType("success");
                setALertMessage(true);
                setCurrentAlertAction({action: ()=>{}})
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    const getDONATE = function (limit=20, page=1, success=()=>{}, failed=()=>{}) {
        setIsLoading(true);
        DONATE.GetAll(limit, page).then((response) => {
            setIsLoading(false);
            if(response.status===200) {
                success(response);
            }
        }).catch((e) => {
            failed(e);
            setIsLoading(false);
        });
    }

    return (
        <div>
            <ContextP.Provider
                value={{
                    isLoading, 
                    setIsLoading, 

                    login,

                    /**----------------------------- */
                    addODD, 
                    getODD,
                    getOneODD,
                    getODDProjectsOrActualities,
                    updateODD,
                    deleteODD,

                    /**----------------------------- */
                    addDONATE,
                    getDONATE,
                    getConfirmDONATE,
                    getUnConfirmDONATE,
                    confirmDONATE,
                    deleteDONATE,

                    /**----------------------------- */
                    addCOMPETENCES, 
                    getCOMPETENCES,
                    getDomaineProjectsOrActualities,
                    getOneDomaine,
                    updateCOMPETENCES,
                    deleteCOMPETENCES,

                    /**----------------------------- */
                    addCATEGORY, 
                    getCATEGORY, 
                    updateCATEGORY,
                    deleteCATEGORY,

                    /**----------------------------- */
                    addARTICLE,
                    addODDToARTICLE,
                    updateARTICLE_ODD,
                    getARTICLE_ODD,

                    addProjectToARTICLE,
                    getARTICLE_PROJECTS,
                    updateARTICLE_PROJECTS,

                    addAlbumToARTICLE,
                    getARTICLE_ALBUM,
                    deleteARTICLE_ALBUM,


                    addExpertiseToARTICLE,
                    updateARTICLE_Expertise,
                    getARTICLE_Expertise,
                    getARTICLE,
                    getOneARTICLE,
                    updateARTICLE,
                    deleteARTICLE,

                    /**----------------------------- */
                    addPROJECT, 
                    getPROJECT, 
                    getOnePROJECT,
                    updatePROJECT,
                    deletePROJECT,

                    addODDToPROJECT,
                    updatePROJECT_ODD,
                    getPROJECT_ODD,

                    addAlbumToPROJECT,
                    deletePROJECT_ALBUM,
                    getPROJECT_ALBUM,


                    addExpertiseToPROJECT,
                    updatePROJECT_Expertise,
                    getPROJECT_Expertise,

                    /**----------------------------- */
                    addMEDIA, 
                    getMEDIA, 
                    updateMEDIA,
                    deleteMEDIA,

                    /**----------------------------- */
                    setALertMessage,
                    setCurrentAlertMessage,
                    setCurrentAlertType,
                    setCurrentAlertAction
                }}
            >
                {children}
                {
                    isLoading ?
                        <div>
                            <div className='globalALert_back'></div>
                                <div className='globalALert'>
                                    <div className='globalALert_content'>
                                        <div className='globalALert_content_loader'>
                                            <img src={loader} alt='freeJobs'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                            null
                }

                {
                    ALertMessage ?
                        <div>
                            <div className='globalALert_back'></div>
                            <div className='globalALert'>
                                <div className='globalALert_content'>
                                    <Alert style={{backgroundColor: "#ffffff"}} variant="outlined" elevation={6} severity={currentAlertType} onClose={() => {
                                        setALertMessage(false);
                                        currentAlertAction.action();
                                    }}>{currentAlertMessage}</Alert>
                                </div>
                            </div>
                        </div>
                    :
                        null
                }
            </ContextP.Provider>
        </div>
    )
}