import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();

class ProjectRequest {
    add(data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "fonctions/projets/ajouter/",
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        )
    }

    addODD(id, data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "fonctions/projets/" + id + "/associer-odd/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    addAlbum(id, data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "fonctions/projets/" + id + "/ajouter-images/",
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        )
    }

    getAlbum(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "fonctions/projets/" + id + "/images/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    deleteAlbum(id, data) {
        // console.log(data);
        return (
            axios({
                method: 'delete',
                data: data,
                url: PATH__.api_root + "fonctions/projets/" + id + "/supprimer-images/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    updateODD(id, data) {
        return (
            axios({
                method: 'put',
                data: data,
                url: PATH__.api_root + "fonctions/projets/" + id + "/modifier-odd/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetODD(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "fonctions/projets/" + id + "/odd/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    addExpertise(id, data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "fonctions/projets/" + id + "/associer-domaines/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    updateExpertise(id, data) {
        return (
            axios({
                method: 'put',
                data: data,
                url: PATH__.api_root + "fonctions/projets/" + id + "/modifier-domaines/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetExpertise(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "fonctions/projets/" + id + "/domaines/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetAll(limit = 20, page = 1) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "fonctions/projets/liste/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetOne(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "fonctions/projet/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    update(id, data) {
        return (
            axios({
                method: 'put',
                data: data,
                url: PATH__.api_root + "fonctions/projets/modifier/" + id + "/",
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        )
    }

    delete(id) {
        return (
            axios({
                method: 'delete',
                url: PATH__.api_root + "fonctions/projets/supprimer/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }
}

export default ProjectRequest;