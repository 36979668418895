import React, {useState} from 'react';

/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './style/style.scss';
import './style/responsive.style.scss';


import VARIABLES from '../../styles/model-7-1/utils/_variables.scss';




/**----------------------------------------------------------------------------------------*/
/**Importation des images*/


function MenuComponent( props ) {
    const [currentMenu] = useState(-1);
    const [openMenu, setOpenMenu] = useState(false);
    const [currentMenuItem, setCurrentMenuItem] = useState(1);
    // const [currentSubMenu, setCurrentSubMenu] = useState({
    //     parent: -1,
    //     sub: -1
    // });
    const [currentSubMenuOpen] = useState([]);
    const VAR = VARIABLES;

    function _generateMenu(listElementsOfMenu, parent=null) {
        return (
            <ul
                className={parent==null ? "mainMenu" : "submenu"}
            >
                {
                    listElementsOfMenu.map((menu, index) => (
                        <li className="kando-header-nav-menu-item hoverStyle1-container" key={index + "-" + menu.name}>
                            <a
                                className={"kando-header-nav-menu-item-link " + (parent==null?"hoverStyle2":"")}
                                variant="contained"
                                href={menu.link ? menu.link : "#"}
                                onClick={() => {
                                    setCurrentMenuItem(index);
                                    return true;
                                }}
                            >
                                <div className='dashboard-menu-content-item_'>
                                    {
                                        menu.icon &&
                                            <span className='dashboard-menu-content-item-icon'>
                                                { menu.icon }
                                            </span>
                                    }
                                    <span className='dashboard-menu-content-item-text'>
                                        {menu.name}
                                    </span>
                                </div>
                            </a>
                            {
                                currentMenu === index &&
                                <div className='currentMenuBaseLine'></div>
                            }
                            {
                                menu.subMenus ?
                                    <div className='subMenu_container'
                                        style={{
                                            opacity: !currentSubMenuOpen.includes(menu.name) ? 1 : 0,
                                            maxHeight: !currentSubMenuOpen.includes(menu.name) ? "50em" : "0em",
                                        }}
                                    >
                                        {/* <div className='menuJoin'></div> */}
                                        <div className='SubMenu'>
                                            {
                                                _generateMenu(menu.subMenus, menu.name)
                                            }
                                        </div>
                                    </div>
                                :
                                    null
                            }
                        </li>
                    ))
                }
            </ul>
        )
    }

    return (
        <>
            <nav className='kando-header-nav flex-row justify-between align-center'>
                <ul className="flex-row align-center kando-header-nav-menu"
                    style={{
                        transform: openMenu ? "translate(0%, 0)" : "translate(100%, 0)"
                    }}
                >
                    <div className='kando-header-nav-menu-cross'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                            onClick={() => {
                                setOpenMenu(false);
                            }}
                        >
                            <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#ffffff"/>
                            <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#ffffff"/>
                        </svg>
                    </div>

                    {
                        _generateMenu(props.list_menu)
                    }
                    <li>
                        <a style={{padding: "7px 10px"}} className='button-style-1 connection' href='/donate'>
                            Faire un don
                        </a>
                    </li>
                </ul>
                <div className='menu-back'
                    style={{
                        transform: openMenu ? "translate(0%, 0)" : "translate(-100%, 0)"
                    }}
                    onClick={() => {
                        setOpenMenu(false);
                    }}
                ></div>
            </nav>
            <div className='kando-header-menuIcon color-primary'>
                <svg width="44" height="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                        setOpenMenu(true);
                    }}
                >
                    <path d="M3 17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V17C21 16.4477 20.5523 16 20 16H4C3.44772 16 3 16.4477 3 17V17ZM3 12C3 12.5523 3.44772 13 4 13H20C20.5523 13 21 12.5523 21 12V12C21 11.4477 20.5523 11 20 11H4C3.44772 11 3 11.4477 3 12V12ZM4 6C3.44772 6 3 6.44772 3 7V7C3 7.55228 3.44772 8 4 8H20C20.5523 8 21 7.55228 21 7V7C21 6.44772 20.5523 6 20 6H4Z" fill={VAR.color_1}/>
                </svg>
            </div>
        </>
    )
}
export default MenuComponent;