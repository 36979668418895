import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();

class ArticleRequest {
    add(data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "fonctions/actualites/ajouter/",
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        )
    }

    addODD(id, data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "fonctions/actualites/" + id + "/associer-odd/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    addProject(id, data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "fonctions/actualites/" + id + "/associer-projets/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    updatePROJECTS(id, data) {
        return (
            axios({
                method: 'put',
                data: data,
                url: PATH__.api_root + "fonctions/actualites/" + id + "/modifier-projets/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetPROJECTS(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "fonctions/actualites/" + id + "/projets/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    addAlbum(id, data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "fonctions/actualites/" + id + "/ajouter-images/",
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        )
    }

    getAlbum(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "fonctions/actualites/" + id + "/images/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    deleteAlbum(id, data) {
        // console.log(data);
        return (
            axios({
                method: 'delete',
                data: data,
                url: PATH__.api_root + "fonctions/actualites/" + id + "/supprimer-images/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    updateODD(id, data) {
        return (
            axios({
                method: 'put',
                data: data,
                url: PATH__.api_root + "fonctions/actualites/" + id + "/modifier-odd/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetODD(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "fonctions/actualites/" + id + "/odd/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    addExpertise(id, data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "fonctions/actualites/" + id + "/associer-domaines/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    updateExpertise(id, data) {
        return (
            axios({
                method: 'put',
                data: data,
                url: PATH__.api_root + "fonctions/actualites/" + id + "/modifier-domaines/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetExpertise(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "fonctions/actualites/" + id + "/domaines/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetAll(limit = 20, page = 1) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "fonctions/actualites/liste/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetOne(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "fonctions/actualites/detail/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    update(id, data) {

        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "fonctions/actualites/modifier/" + id + "/",
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        )
    }

    delete(id) {
        return (
            axios({
                method: 'delete',
                url: PATH__.api_root + "fonctions/actualites/supprimer/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }
}

export default ArticleRequest;