// import React, { useContext , useEffect} from 'react';

import React, {useState, useContext, useEffect} from 'react';

import {
    useParams
} from "react-router-dom";

// import { ContextP } from "../context/provider";

import '../styles/aboutUs.screen.style.scss';

import PATH_ from '../GLOBAL-VAR/globals-urls';

import { ContextP } from "../context/provider";

import Card from '../components/card/card.component';

/**Importation des images */

function Projects( props ) {
    const { type } = useParams();

    const {getPROJECT} = useContext(ContextP);

    const [TYPE, setTYPE] = useState(null);

    const [ARTICLE, setARTICLE] = useState(null);

    const path_ = new PATH_();

    function _get_article(page) {
        getPROJECT(20,page,(res)=>{
            // console.log(res);
            setARTICLE(res.data);
        }, (e)=>{
            return false;
        });
    }

    useEffect(() => {
        _get_article(1);
        if(type) {
            setTYPE(type);
        } else {
            setTYPE("en cours");
        }
    }, [setTYPE])
    
    return (
        <div className='AboutUsContent'>
            <div className='AboutUsContent_section'>
                <div className='AboutUsContent_section_back'></div>
                <div className='AboutUsContent_section_content'>
                    <div className='AboutUsContent_section_content_new'>
                        <div>
                            <h2>
                                NOS PROJETS
                            </h2>
                        </div>
                        <div className='AboutUsContent_section_content_new_info'>
                            <a href='/contact' className='button-style-3'>
                                NOUS CONTACTER
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='AboutUsContent_section3'>
                <div className='AboutUsContent_section3_back'></div>
                <div className='AboutUsContent_section3_content'>
                    <div className="AboutUsContent_section3_content_enc">
                        <span>Ce que nous offrons</span>
                    </div>
                    <div className='AboutUsContent_section3_content_text'>
                        <div>
                            <h2>
                                
                            </h2>
                        </div>
                        <div className='AboutUsContent_section3_content_text_p'>
                            <p>
                                Sur erepere.fr, vous trouverez une mine d'informations précieuses sur la fiscalité, les réglementations, les procédures douanières et les opportunités d'investissement au Bénin. Notre équipe d'experts-conseils est là pour répondre à vos questions spécifiques, vous guider à travers les défis potentiels et vous aider à prendre des décisions éclairées.
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='homeViewContent_section5_expert'>
                <div className='homeViewContent_section5_expert_text'>
                    <p></p>
                    <a href='/projects/en cours' className={TYPE==="en cours" ? "p_c_current" : ""}>
                        EN COURS
                    </a>
                    <a href='/projects/termines' className={TYPE==="termines" ? "p_c_current" : ""}>
                        TERMINÉS
                    </a>
                </div>

                <div className='homeViewContent_section5_expert_list'>
                    <div className='homeViewContent_section4'>
                        <div className=''>
                            <div className="homeViewContent_section4_content_list">
                                {
                                    ARTICLE!==null &&
                                    ARTICLE.projets.map((article, index)=>(
                                        index < 3 &&
                                        <div className='homeViewContent_section4_content_list_elt' key={"news" + index}>
                                            <Card elt={article} link={"/project/"+article.id}/>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className='homeViewContent_section5_expert_button'>
                    <a href='##' className='button-style-2'>
                        VOIR TOUS LES EXPERTS ET DEMMANDER UN RENDEZ-VOUS
                    </a>
                </div> */}
            </div>
        </div>
    );
}

export default Projects;