import React, {useState, useContext, useEffect} from 'react';

// import { ContextP } from "../context/provider";

import '../styles/oneNewsView.screen.style.scss';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';

import {
  useParams
} from "react-router-dom";

import PATH_ from '../GLOBAL-VAR/globals-urls';

import { ContextP } from "../context/provider";

// import Box from '@mui/material/Box';
// import ImageList from '@mui/material/ImageList';
// import ImageListItem from '@mui/material/ImageListItem';

import Card from '../components/card/card.component';

dayjs.locale('fr');

function OneODDView( props ) {
    const { odd } = useParams();
    
    const {getOneODD} = useContext(ContextP);
    const {getODDProjectsOrActualities} = useContext(ContextP);

    const [ODD, setODD] = useState(null);
    const [ProjectsAndActualities, setProjectsAndActualities] = useState(null);

    const path_ = new PATH_();

    function _get_ODD(id) {
      getOneODD(id,(res)=>{
        // console.log(res.data);
          setODD(res.data);
      }, (e)=>{
          return false;
      });
    }

    function _get_ODD_ProjectsOrActualities(id) {
      getODDProjectsOrActualities(id,(res)=>{
        // console.log(res.data);
        setProjectsAndActualities(res.data);
      }, (e)=>{
          return false;
      });
    }

    useEffect(() => {
      // console.log(current);
      if(odd !== null) {
        _get_ODD(odd);
        _get_ODD_ProjectsOrActualities(odd);
      }
    }, [])

    return (
        <div className='homeViewContent'>
            <div className=''>
              {
                ODD !== null &&
                  <div className="article-page">
                    {/* <header className="article-header">
                      <h1>{ODD.nom}</h1>
                    </header> */}

                    <div>
                      <img src={"/ODD/odd" + ODD.numero + ".png"} alt={ODD.nom} className="odd-image" />
                      {/* <div className="article-meta">
                        <strong>{dayjs(ARTICLE.date_debut).format('dddd, DD MMMM, YYYY')}</strong> au <strong>{dayjs(ARTICLE.date_fin).format('dddd, DD MMMM, YYYY')}</strong>
                      </div> */}

                      <div className="OnProjectView_top_subtext">
                            <a href={ODD.lien} className="odd-link title">
                                #{ODD.lien}
                            </a>
                        </div>
                        <div className="OnProjectView_top_competence">
                            
                        </div>
                      </div>

                    <main className="article-content">
                      <br/>
                      <p dangerouslySetInnerHTML={{__html: ODD.description}}></p>
                    </main>

                    
                  </div>
              }

              {
                ProjectsAndActualities !== null ?
                  <div className='homeViewContent_section4' style={{background: "#ffffff"}}>
                    <div className='homeViewContent_section4_content' style={{background: "#ffffff"}}>
                        <div className="homeViewContent_section4_content_enc">
                            <span>Projets</span>
                        </div>

                        <div className="homeViewContent_section4_content_list">
                            {
                                ProjectsAndActualities.projets!==null &&
                                ProjectsAndActualities.projets.map((project, index)=>(
                                    <div className='homeViewContent_section4_content_list_elt' key={"projects" + index}>
                                        <Card elt={project} link={"/project/"+project.id} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                : null
              }

              {
                ProjectsAndActualities ?
                  <div className='homeViewContent_section4'>
                    <div className='homeViewContent_section4_content'>
                        <div className="homeViewContent_section4_content_enc">
                            <span>Actualités</span>
                        </div>

                        <div className="homeViewContent_section4_content_list">
                            {
                                ProjectsAndActualities.actualites!==null &&
                                ProjectsAndActualities.actualites.map((article, index)=>(
                                    index < 3 &&
                                    <div className='homeViewContent_section4_content_list_elt' key={"news" + index}>
                                        <Card elt={article} link={"/news/"+article.id} type="article"/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                : null
              }
            </div>
        </div>
    );
}

export default OneODDView;