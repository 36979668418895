import React, {useState, useContext, useEffect} from 'react';
import {
    useParams
} from "react-router-dom";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";

import '../styles/dashboard.faq.style.scss';

import { ContextP } from "../context/provider";

function DashboardAdminArticle( props ) {
    const { page } = useParams();


    const {getCATEGORY} = useContext(ContextP);
    const {getARTICLE} = useContext(ContextP);
    const {addARTICLE} = useContext(ContextP);
    const {updateARTICLE} = useContext(ContextP);
    const {deleteARTICLE} = useContext(ContextP);

    const {addMEDIA} = useContext(ContextP);

    // const {setALertMessage} = useContext(ContextP);
    // const {setCurrentAlertMessage} = useContext(ContextP);
    // const {setCurrentAlertType} = useContext(ContextP);
    // const {setCurrentAlertAction} = useContext(ContextP);


    const [ARTICLE, setARTICLE] = useState(null);
    const [CATEGORY, setCATEGORY] = useState([]);
    const [actionType, setActionType] = useState("add");
    const [currentARTICLE, setCurrentARTICLE] = useState(null);
    const [currentARTICLECat, setCurrentARTICLECat] = useState([]);

    function _add_() {

        let cat = [];
        CATEGORY.map((category)=>{
            if(category.name==="Actualité") {
                cat.push(category.id);
            }
            if(category.name==="À la une") {
                if(document.forms["actualityForm"]["une"].checked) {
                    cat.push(category.id);
                }
            }
        });

        if(document.forms["actualityForm"]["file"].files.length>0) {
            let media = new FormData();
            media.append("alt", "actualité erepere");
            media.append("description", "");
            media.append("file", document.forms["actualityForm"]["file"].files[0]);

            addMEDIA(media, (res)=>{
                // console.log(currentARTICLE);
                addARTICLE(currentARTICLE, res.data.id, cat, (res)=>{
                    setCurrentARTICLE(null);
                    if(page) {
                        _get_(page);
                    } else {
                        _get_(1);
                    }
                }, (e)=>{
                    console.log(e);
                });
            }, (e)=>{
                console.log(e);
            })
        } else {
            addARTICLE(currentARTICLE, -1, cat, (res)=>{
                setCurrentARTICLE(null);
                if(page) {
                    _get_(page);
                } else {
                    _get_(1);
                }
            }, (e)=>{
                console.log(e);
            });
        }
    }

    function _update_() {
        if(window.confirm("Voulez-vous vraiment modifier cette actualité ?")) {
            updateARTICLE(currentARTICLE.id, currentARTICLE, (res)=>{
                setCurrentARTICLE(null);
                if(page) {
                    _get_(page);
                } else {
                    _get_(1);
                }
            }, (e)=>{
                console.log(e);
            })
        }
    }

    function _delete_(id) {
        // console.log(id);
        if(window.confirm("Voulez-vous vraiment supprimer cette actualité ?")) {
            deleteARTICLE(id, (res)=>{
                setCurrentARTICLE(null);
                // setALertMessage(false);
                if(page) {
                    _get_(page);
                } else {
                    _get_(1);
                }
            }, (e)=>{
                console.log(e);
            })
        }
    }

    function _get_(page) {
        getARTICLE(20,page,(res)=>{
            // console.log(res);
            setARTICLE(res.data);
        }, (e)=>{
            return false;
        });
    }

    function _get_category(page) {
        getCATEGORY(20000,page,(res)=>{
            // console.log(res);
            setCATEGORY(res.data.data);
        }, (e)=>{
            return false;
        });
    }

    useEffect(() => {
        _get_category(1);
        if(page) {
            _get_(page);
        } else {
            _get_(1);
        }
    }, [setARTICLE, setCATEGORY])

    return (
        <div className='faq'>
            <div className='faq_content'>
                <div className='faq_content_button'>
                    <button
                        onClick={(e)=>{
                            setCurrentARTICLE({
                                title: "",
                                summary: "",
                                body: "",
                                state: "Published",                         
                            });
                        }}
                    >AJOUTER</button>
                </div>
                <br/>
                <div>
                    <table className='table-classic'>
                        <thead>
                            <tr>
                                <th>TITRE</th>
                                <th>SOMMAIRE</th>
                                <th>STATUS</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ARTICLE!==null &&
                                ARTICLE.data.map((article, index)=>(
                                    <tr style={{
                                        background: index%2===0 ? "#00000005" : "transparent",
                                    }}>
                                        <td><strong>{article.title}</strong></td>
                                        <td><div className='content' dangerouslySetInnerHTML={{__html: article.summary}}></div></td>
                                        <td><strong>{article.status}</strong></td>
                                        <td>
                                            <button className='update'
                                                onClick={(e)=>{
                                                    setActionType("update");
                                                    setCurrentARTICLE(article);
                                                }}
                                            >
                                                Modifier
                                            </button>
                                            <button className='delete'
                                                onClick={(e)=>{
                                                    _delete_(article.id);
                                                }}
                                            >
                                                supprimer
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <br/>
                    <br/>

                    {
                        ARTICLE!==null &&
                        <Box
                            sx={{
                                margin: "auto",
                                width: "fit-content",
                                alignItems: "center",
                            }}
                        >
                            <Pagination count={ARTICLE.totalPage} page={ARTICLE.page} 
                                onChange={(e, value)=>{
                                    _get_(value);
                                    window.history.replaceState({}, '', '/dashboard/admin/actuality/' + value);
                                }} variant="outlined" color="primary" />
                        </Box>
                    }
                    
                </div>
            {
                currentARTICLE !== null ?
                    <section className='information'>
                        <div className='information_content'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setCurrentARTICLE(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="information_content_head">
                                <div className="information_content_head_id"></div>
                            </div>

                            <form action='#' method='POST' className='form' name="actualityForm"
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                    if(actionType==="add") {
                                        _add_();
                                    } else {
                                        _update_();
                                    }
                                }}
                            >
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>TITRE</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={currentARTICLE.title?currentARTICLE.title:""} name='name' required placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setCurrentARTICLE((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "title": e.target.value
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Le titre de l'actualité</span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>SOMMAIRE</span>
                                    </div>
                                    <div className='reactQuillEditor'>
                                        <ReactQuill theme="snow" value={currentARTICLE.summary?currentARTICLE.summary:""} onChange={(value) => {
                                            setCurrentARTICLE((prev)=>{
                                                return({
                                                    ...prev,
                                                    "summary": value
                                                });
                                            })
                                        }} />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Une courte description de l'actualité</span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>DESCRIPTION</span>
                                    </div>
                                    <div className='reactQuillEditor'>
                                        <ReactQuill theme="snow" value={currentARTICLE.body?currentARTICLE.body:""} onChange={(value) => {
                                            setCurrentARTICLE((prev)=>{
                                                return({
                                                    ...prev,
                                                    "body": value
                                                });
                                            })
                                        }} />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>La description de l'actualité</span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>CATÉGORIES</span>
                                    </div>
                                    <br/>
                                    {
                                        CATEGORY.map((cat, index) => (
                                            cat.name !== "À la une" && cat.name !== "Actualité" &&
                                            <div className='form_input_box' style={{marginBottom: "10px"}}
                                                onChange={(e)=>{
                                                    let list = currentARTICLECat;
                                                    if(e.target.checked) {
                                                        list.push(cat.id);
                                                    } else {
                                                        list.splice(list.indexOf(cat.id), 1);
                                                    }
                                                    setCurrentARTICLECat(list);
                                                }}
                                            >
                                                <input id={cat.name} name='une' type='checkbox'/>
                                                <label style={{color: "#5dae00", marginLeft: "5px"}} htmlFor={cat.name}>{cat.name}</label>
                                            </div>
                                        ))
                                    }
                                    
                                </div>

                                <div className='form_button'>
                                    <button>
                                        Ajouter
                                    </button>
                                </div>
                            </form>
                        </div>
                    </section>
                :
                    null
            }
            </div>
        </div>
    );
}

export default DashboardAdminArticle;