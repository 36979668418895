// import React, { useContext , useEffect} from 'react';

import React from 'react';

// import { ContextP } from "../context/provider";

import '../styles/aboutUs.screen.style.scss';
import TeamCard from '../components/teamCard/teamCard.component';

/**Importation des images */
import mb8 from '../assets/images/experts/pres.jpg';
import mb9 from '../assets/images/experts/blank-profile.png';

function AboutUs( props ) {
    
    const team = [
        {
            name: "administrative",
            list: [{
                "id": 1,
                "name": "Abdoul Bassiti ISSA",
                "position": "Président",
                "description": "Abdoul Bassiti ISSA est président depuis 2020.",
                "image": mb8,
                "socialLinks": [
                    {
                        "icon": "facebook",
                        "url": "https://www.facebook.com/johndoe"
                    },
                    {
                        "icon": "twitter",
                        "url": "https://twitter.com/johndoe"
                    },
                    {
                        "icon": "linkedin",
                        "url": "https://www.linkedin.com/in/johndoe"
                    }
                ]
            },{
                    "id": 1,
                    "name": "Dr Atindehou",
                    "position": "Vice président",
                    "description": "Le Dr Atindehou est vise président depuis 2024.",
                    "image": mb9,
                    "socialLinks": [
                        {
                            "icon": "facebook",
                            "url": "https://www.facebook.com/johndoe"
                        },
                        {
                            "icon": "twitter",
                            "url": "https://twitter.com/johndoe"
                        },
                        {
                            "icon": "linkedin",
                            "url": "https://www.linkedin.com/in/johndoe"
                        }
                    ]
                },
                {
                    "id": 2,
                    "name": "Youssouf Abdraman",
                    "position": "---",
                    "description": "",
                    "image": mb9,
                    "socialLinks": [
                        {
                            "icon": "facebook",
                            "url": "https://www.facebook.com/janesmith"
                        },
                        {
                            "icon": "twitter",
                            "url": "https://twitter.com/janesmith"
                        },
                        {
                            "icon": "linkedin",
                            "url": "https://www.linkedin.com/in/janesmith"
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Fadilath",
                    "position": "---",
                    "description": "",
                    "image": mb9,
                    "socialLinks": [
                        {
                            "icon": "facebook",
                            "url": "https://www.facebook.com/maryjohnson"
                        },
                        {
                            "icon": "twitter",
                            "url": "https://twitter.com/maryjohnson"
                        },
                        {
                            "icon": "linkedin",
                            "url": "https://www.linkedin.com/in/maryjohnson"
                        }
                    ]
                }
            ],
        },
        {
            name: "Support technique",
            list: [{
                "id": 11,
                "name": "Therin Zinsou HOUNNOUKPE",
                "position": "Développeur frontend",
                "description": "---",
                "image": mb9,
                "socialLinks": [
                    {
                        "icon": "facebook",
                        "url": "https://www.facebook.com/bobjohnson"
                    },
                    {
                        "icon": "twitter",
                        "url": "https://twitter.com/bobjohnson"
                    },
                    {
                        "icon": "linkedin",
                        "url": "https://www.linkedin.com/in/bobjohnson"
                    }
                ]
            },
            {
                "id": 12,
                "name": "Josh SONON",
                "position": "Développeut backend",
                "description": "---",
                "image": mb9,
                "socialLinks": [
                    {
                        "icon": "facebook",
                        "url": "https://www.facebook.com/alicelee"
                    },
                    {
                        "icon": "twitter",
                        "url": "https://twitter.com/alicelee"
                    },
                    {
                        "icon": "linkedin",
                        "url": "https://www.linkedin.com/in/alicelee"
                    }
                ]
            }
        ]}
    ]
    return (
        <div className='AboutUsContent'>
            <div className='AboutUsContent_section'>
                <div className='AboutUsContent_section_back'></div>
                <div className='AboutUsContent_section_content'>
                    <div className="AboutUsContent_section_content_enc">
                        <span>Nous sommes</span>
                    </div>
                    <div className='AboutUsContent_section_content_new'>
                        <div>
                            <h2>
                                L'Organisation pour la Restauration de l'Espoir des Démunis de la Société (OREDS).
                            </h2>
                        </div>
                        <div className='AboutUsContent_section_content_new_info'>
                            <a href='/contact' className='button-style-3'>
                                NOUS CONTACTER
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='AboutUsContent_section2'>
                <div className='AboutUsContent_section2_back'></div>
                <div className='AboutUsContent_section2_content'>
                    <div className="AboutUsContent_section2_content_enc">
                        <span>Présentation de l'organisation</span>
                    </div>
                    <div className='AboutUsContent_section2_content_text'>
                        <div>
                            <h2>
                                
                            </h2>
                        </div>
                        <div className='AboutUsContent_section2_content_text_p'>
                            <p>
                                L'Organisation pour la Restauration de l'Espoir des Démunis de la Société (OREDS) est une association à but non lucratif créée en 2020. Notre mission est d'aider les personnes les plus démunies à retrouver espoir et dignité.
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div className='AboutUsContent_section3'>
                <div className='AboutUsContent_section3_back'></div>
                <div className='AboutUsContent_section3_content'>
                    <div className="AboutUsContent_section3_content_enc">
                        <span>Vision</span>
                    </div>
                    <div className='AboutUsContent_section3_content_text'>
                        <div className='AboutUsContent_section3_content_text_p'>
                            <p>
                                Notre vision est de construire un monde où chacun a accès aux besoins fondamentaux et où les inégalités sociales sont réduites. Nous croyons en la solidarité, l'entraide et le respect de la dignité humaine.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='AboutUsContent_section2'>
                <div className='AboutUsContent_section2_back'></div>
                <div className='AboutUsContent_section2_content'>
                    <div className="AboutUsContent_section2_content_enc">
                        <span>Historique de l'organisation</span>
                    </div>
                    <div className='AboutUsContent_section2_content_text'>
                        <div>
                            <h2>
                                
                            </h2>
                        </div>
                        <div className='AboutUsContent_section2_content_text_p'>
                            <p>
                                L'OREDS a été créée en 2020 par un groupe de bénévoles engagés dans la lutte contre la pauvreté et l'exclusion sociale. Depuis sa création, l'OREDS a mené de nombreuses actions en faveur des personnes les plus démunies, telles que des distributions alimentaires, des maraudes, des ateliers d'insertion professionnelle, etc.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='AboutUsContent_section4'>
                {/* <div className='AboutUsContent_section2_back'></div> */}
                <div className='AboutUsContent_section4_content'>
                    <div className="AboutUsContent_section2_content_enc">
                        <span>L'equipe</span>
                    </div>
                    <div className='AboutUsContent_section2_content_members'>
                        {
                            team.map((tm, index) => (
                                <div key={tm.name + "/" + index} className='AboutUsContent_section2_content_members_groups'>
                                    <h2>
                                        {tm.name}
                                    </h2>
                                    <div className='AboutUsContent_section2_content_members_groups_list'>
                                        {
                                            tm.list.map((mb, index_)=>(
                                                <div className='AboutUsContent_section2_content_members_groups_list_elt'>
                                                    <TeamCard mb={mb} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;