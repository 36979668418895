import React from 'react';
// import { Link } from 'react-router-dom';

import './style/style.scss';
import './style/responsive.style.scss';


function TeamCard(props){
    return (
        <div className="team-member-card">
            <img src={props.mb.image} alt={props.mb.name} className="team-member-card__image" />
            <div className='team-member-card-body'>
                <h3 className="team-member-card__name"><a href='##'>{props.mb.name}</a></h3>
                <p className="team-member-card__position">{props.mb.position}</p>
                <p className="team-member-card__description">{props.mb.description}</p>
                <ul className="team-member-card__social-links">
                    {props.mb.socialLinks.map((link, index) => (
                        <li key={index} className="team-member-card__social-link">
                            <a href={link.url} target="_blank" rel="noopener noreferrer">
                            <i className={`fa fa-${link.icon}`}></i>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default TeamCard;