import React from 'react';

import './style/style.scss';
import './style/responsive.style.css';

import drag_and_drop_icon1 from '../../assets/icons/drag-and-drop-icon-29.jpg';
import drag_and_drop_icon2 from '../../assets/icons/drag-and-drop-icon.png';

class ImageSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dragHover: false,
        }

    }

    componentDidMount() {

    }


    render() {
        return (
            <div className="imageSelector">
                <div className="imageSelector_dragDrop" id="dropC"
                    onDrop={(e) => {
                        e.preventDefault() ;
                        document.getElementById( "dropC" ).style.transform = "scale(1)";
                        this.setState({
                            dragHover: false,
                        });
                        this.props.onChangeImage( e.dataTransfer.files );
                    }}

                    onDragEnter={(e) => {
                        this.setState({
                            dragHover: true,
                        });
                        document.getElementById( "dropC" ).style.transform = "scale(1.1)";
                    }}

                    onDragLeave={(e) => {
                        this.setState({
                            dragHover: false,
                        });
                        document.getElementById( "dropC" ).style.transform = "scale(1)";
                    }}

                    onDragOver={(e) => {
                        this.setState({
                            dragHover: true,
                        });
                        document.getElementById( "dropC" ).style.transform = "scale(1.1)";
                        e.preventDefault() ;
                    }}
                >
                    <img
                        src={
                            this.state.dragHover ? drag_and_drop_icon2 : drag_and_drop_icon1
                        }
                        className="imageSelector_dragDrop_image"
                        alt='RAD-ONG'
                    />
                    <span>
                        glisser et déposer ici
                    </span>
                </div>
                <div className="imageSelector_input">
                    <span>Choisir une image</span>
                    <input type="file" multiple onChange={( e ) => {
                        this.props.onChangeImage( e.target.files );
                        e.target.value = '';
                    }}/>
                </div>
            </div>
        )
    }
}

export default ImageSelector;